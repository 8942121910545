import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Login from '../views/Login.vue'
import Registration from '../views/Registration.vue'
import ResetPassword from '../views/ResetPassword.vue'
import NewPasswordForm from '../views/NewPasswordForm.vue'
import Dashboard from '../views/Dashboard.vue'
import PersonalData from '../views/PersonalData.vue'
import PartnerPersonalData from '../views/PartnerPersonalData.vue'
import UpdatePassword from '../views/UpdatePassword.vue'
import BookingOverview from '../views/BookingOverview.vue'
import HelpFAQ from '../views/HelpFAQ.vue'
import LockCard from '../views/LockCard.vue'
import Contact from '../views/Contact.vue'
import Imprint from '../views/Imprint.vue'
import ConditionsOfParticipation from '../views/ConditionsOfParticipation.vue'
import DeclarationOfConsent from '../views/DeclarationOfConsent.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import DataProtection from '../views/DataProtection.vue'
import InterestRegistration from '../views/InterestRegistration.vue'
import PartnerInfo from '../views/PartnerInfo.vue'
import PartnerRegistration from '../views/PartnerRegistration.vue'
import Terminbuchung from '../views/Terminbuchung.vue'
import UserRoles from './../enums/UserRoles'
import PartnerContact from '../views/PartnerContact.vue'
import PartnerLogin from '../views/PartnerLogin.vue'
import InterestBonusInfo from '../views/InterestBonusInfo.vue'
import PartnerSetBonus from '../views/PartnerSetBonus.vue'
import PartnerBooking from '../views/PartnerBooking.vue'
import PartnerCorrectionBooking from '../views/PartnerCorrectionBooking.vue'
import PartnerConditionsOverview from '../views/PartnerConditionsOverview.vue'
import EmployerConditionsOverview from '../views/EmployerConditionsOverview.vue'
import EmployerRegistration from '../views/EmployerRegistration.vue'
import EmployerLogin from '../views/EmployerLogin.vue'
import EmployerPersonalData from '../views/EmployerPersonalData.vue'
import PartnerTransactions from '../views/PartnerTransactions.vue'
import PartnerStatistics from '../views/PartnerStatistics.vue'
import InterestDemoStatistics from '../views/InterestDemoStatistics.vue'
import EditLogo from '../views/EditLogo.vue'
import PartnerEmployerDocuments from '../views/PartnerEmployerDocuments.vue'
import InterestDemoBooking from '../views/InterestDemoBooking.vue'
import InterestDemoTransactions from '../views/InterestDemoTransactions.vue'
import Maintenance from '../views/Maintenance.vue'
import EmployerManageCards from '../views/EmployerManageCards.vue'
import Abfrage from '../views/Abfrage.vue'
import ContractorLogin from '../views/ContractorLogin.vue'
import ContractorStatistics from '../views/ContractorStatistics.vue'
import TrolleymakerportalLogin from '../views/TrolleymakerportalLogin.vue'
import PushNotifications from '../views/PushNotifications.vue'
import PasswordResetList from '../views/PasswordResetList.vue'
import CheckBalanceLinkGenerator from '../views/CheckBalanceLinkGenerator.vue'
import PotentialPartnersLinkGenerator from '../views/PotentialPartnersLinkGenerator.vue'
import PartnerUserRoles from '../enums/PartnerUserRoles'
import PartnerFAQ from '../views/PartnerFAQ.vue'
import EmployerFAQ from '../views/EmployerFAQ.vue'

function isLoggedIn(to, from, next) {
  (store.state.loginStatus) ? next() : next({ name: 'Login' })
}

function isLoggedOut(to, from, next) {
  (store.state.loginStatus) ? next('/') : next()
}

function isCustomer(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'Login' })
  } else {
    if (store.state.user.role === UserRoles.CUSTOMER) {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}

function isInterest(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'PartnerLogin' })
  } else {
    if (store.state.user.role === UserRoles.INTERESTED) {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}

function isInterestAndNotEmployerInterest(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'PartnerLogin' })
  } else {
    if (store.state.user.role === UserRoles.INTERESTED && store.state.user.partner_interest_in !== 'Arbeitgeber') {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}

function isPartner(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'PartnerLogin' })
  } else {
    if (store.state.user.role === UserRoles.PARTNER) {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}
function isEmployer(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'EmployerLogin' })
  } else {
    if (store.state.user.role === UserRoles.EMPLOYER) {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}

function isPartnerOrEmployer(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'EmployerLogin' })
  } else {
    if (store.state.user.role === UserRoles.PARTNER || store.state.user.role === UserRoles.EMPLOYER) {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}

function isTrolleymaker(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'TrolleymakerportalLogin' })
  } else {
    if (store.state.user.role === UserRoles.TROLLEYMAKER) {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}

/*
function isPartnerOrInterest (to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'PartnerLogin' })
  } else {
    if (store.state.user.role === UserRoles.PARTNER || store.state.user.role === UserRoles.INTERESTED) {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}
*/

function isPartnerOrNotEmployerInterest(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'PartnerLogin' })
  } else {
    if (store.state.user.role === UserRoles.PARTNER || (store.state.user.role === UserRoles.INTERESTED && store.state.user.partner_interest_in !== 'Arbeitgeber')) {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}

function isPartnerOrEmployerOrInterest(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'PartnerLogin' })
  } else {
    if (store.state.user.role === UserRoles.PARTNER || store.state.user.role === UserRoles.EMPLOYER || store.state.user.role === UserRoles.INTERESTED) {
      next()
    } else {
      store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie sind nicht berechtigt diese Seite aufzurufen.' })
    }
  }
}

function homepageRedirect(to, from, next) {
  if (!store.state.loginStatus) {
    next({ name: 'Login' })
  } else {
    if (store.state.user.role === UserRoles.CUSTOMER) {
      next({ name: 'Dashboard' })
    } else if (store.state.user.role === UserRoles.INTERESTED) {
      next({ name: 'PartnerInfo' })
    } else if (store.state.user.role === UserRoles.EMPLOYER) {
      next({ name: 'PartnerTransactions' })
    } else if (store.state.user.role === UserRoles.PARTNER && (store.state.user.partner_role === PartnerUserRoles.ADMIN || store.state.user.partner_role === PartnerUserRoles.USER)) {
      next({ name: 'Booking' })
    } else if (store.state.user.role === UserRoles.PARTNER && !(store.state.user.partner_role === PartnerUserRoles.ADMIN || store.state.user.partner_role === PartnerUserRoles.USER)) {
      next({ name: 'PartnerTransactions' })
    } else if (store.state.user.role === UserRoles.TROLLEYMAKER) {
      next({ name: 'PushNotifications' })
    } else {
      next()
    }
  }
}

function checkNewPasswordTokenParameter(to, from, next) {
  if (to.query.t !== undefined && to.query.t !== null && to.query.t !== '') {
    next()
  } else {
    store.commit('ADD_ALERT', { category: 'Fehler', message: 'Der Link ist ungültig. Bitte fordern Sie einen neuen an.' })
    next({ name: 'ResetPassword' })
  }
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    beforeEnter: isLoggedOut,
    component: Login
  },
  {
    path: '/kunden-login',
    redirect: { name: 'Login' }
  },
  {
    path: '/partner-login',
    name: 'PartnerLogin',
    beforeEnter: isLoggedOut,
    component: PartnerLogin
  },
  {
    path: '/trolleymakerportal-login',
    name: 'TrolleymakerportalLogin',
    beforeEnter: isLoggedOut,
    component: TrolleymakerportalLogin
  },
  {
    path: '/auftraggeber-login',
    name: 'ContractorLogin',
    beforeEnter: isLoggedOut,
    component: ContractorLogin
  },
  {
    path: '/contractor-login',
    redirect: { name: 'ContractorLogin' }
  },
  {
    path: '/arbeitgeber-login',
    name: 'EmployerLogin',
    beforeEnter: isLoggedOut,
    component: EmployerLogin
  },
  {
    path: '/registrierung',
    name: 'Registration',
    beforeEnter: isLoggedOut,
    component: Registration
  },
  {
    path: '/registration',
    redirect: { name: 'Registration' }
  },
  {
    path: '/interessent-registrierung',
    name: 'InterestRegistration',
    beforeEnter: isLoggedOut,
    component: InterestRegistration
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    beforeEnter: isLoggedOut,
    component: ResetPassword
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    beforeEnter: [isLoggedOut, checkNewPasswordTokenParameter],
    component: NewPasswordForm
  },
  {
    path: '/',
    name: 'HomepageRedirect',
    beforeEnter: homepageRedirect
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter: isCustomer,
    component: Dashboard
  },
  {
    path: '/partner',
    name: 'PartnerInfo',
    beforeEnter: isPartnerOrEmployerOrInterest,
    component: PartnerInfo
  },
  {
    path: '/partner-info',
    beforeEnter: isPartnerOrEmployerOrInterest,
    redirect: { name: 'PartnerInfo' }
  },
  {
    path: '/akzeptanzpartner-faq',
    name: 'PartnerFAQ',
    beforeEnter: isPartnerOrEmployerOrInterest,
    component: PartnerFAQ
  },
  {
    path: '/arbeitgeber-faq',
    name: 'EmployerFAQ',
    beforeEnter: isPartnerOrEmployerOrInterest,
    component: EmployerFAQ
  },
  {
    path: '/konditionen-kurzuebersicht',
    name: 'PartnerConditionsOverview',
    beforeEnter: isPartnerOrNotEmployerInterest,
    component: PartnerConditionsOverview
  },
  {
    path: '/konditionen-mitarbeitercard',
    name: 'EmployerConditionsOverview',
    beforeEnter: isPartnerOrEmployerOrInterest,
    component: EmployerConditionsOverview
  },
  {
    path: '/bonus',
    name: 'BonusInfo',
    beforeEnter: isPartnerOrNotEmployerInterest,
    component: InterestBonusInfo
  },
  {
    path: '/arbeitgeber',
    beforeEnter: isPartnerOrEmployerOrInterest,
    redirect: { name: 'PartnerInfo' }
  },
  {
    path: '/arbeitgeber-info',
    beforeEnter: isPartnerOrEmployerOrInterest,
    redirect: { name: 'PartnerInfo' }
  },
  {
    path: '/bonusauftrag',
    name: 'Bonus',
    beforeEnter: isPartner,
    component: PartnerSetBonus
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint
  },
  {
    path: '/impressum',
    redirect: { name: 'Imprint' }
  },
  {
    path: '/conditions-of-participation',
    name: 'ConditionsOfParticipation',
    component: ConditionsOfParticipation
  },
  {
    path: '/teilnahmebedingungen',
    redirect: { name: 'ConditionsOfParticipation' }
  },
  {
    path: '/declaration-of-consent',
    name: 'DeclarationOfConsent',
    component: DeclarationOfConsent
  },
  {
    path: '/einwilligungserklaerung',
    redirect: { name: 'DeclarationOfConsent' }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/datenschutzhinweise',
    redirect: { name: 'PrivacyPolicy' }
  },
  {
    path: '/data-protection',
    name: 'DataProtection',
    component: DataProtection
  },
  {
    path: '/datenschutzerklaerung',
    redirect: { name: 'DataProtection' }
  },
  {
    path: '/datenschutz',
    redirect: { name: 'DataProtection' }
  },
  {
    path: '/personal-data',
    name: 'PersonalData',
    beforeEnter: isCustomer,
    component: PersonalData
  },
  {
    path: '/partner-personal-data',
    name: 'PartnerPersonalData',
    beforeEnter: isPartner,
    component: PartnerPersonalData
  },
  {
    path: '/arbeitgeber-personal-data',
    name: 'EmployerPersonalData',
    beforeEnter: isEmployer,
    component: EmployerPersonalData
  },
  {
    path: '/update-password',
    name: 'UpdatePassword',
    beforeEnter: isLoggedIn,
    component: UpdatePassword
  },
  {
    path: '/booking-overview',
    name: 'BookingOverview',
    beforeEnter: isCustomer,
    component: BookingOverview
  },
  {
    path: '/help-faq',
    name: 'HelpFAQ',
    beforeEnter: isCustomer,
    component: HelpFAQ
  },
  {
    path: '/lock-card',
    name: 'LockCard',
    beforeEnter: isCustomer,
    component: LockCard
  },
  {
    path: '/contact',
    name: 'Contact',
    beforeEnter: isCustomer,
    component: Contact
  },
  {
    path: '/partner-contact',
    name: 'PartnerContact',
    beforeEnter: isPartnerOrEmployerOrInterest,
    component: PartnerContact
  },
  {
    path: '/korrektur',
    name: 'PartnerCorrectionBooking',
    beforeEnter: isPartnerOrEmployer,
    component: PartnerCorrectionBooking
  },
  {
    path: '/korrekturbuchung',
    redirect: { name: 'PartnerCorrectionBooking' }
  },
  {
    path: '/mitarbeitercard-teilnehmen',
    redirect: { name: 'PartnerWerden' }
  },
  {
    path: '/terminbuchung',
    name: 'Terminbuchung',
    beforeEnter: isPartnerOrEmployerOrInterest,
    component: Terminbuchung
  },
  {
    path: '/partner-werden',
    name: 'PartnerWerden',
    beforeEnter: isInterestAndNotEmployerInterest,
    component: PartnerRegistration
  },
  {
    path: '/arbeitgeber-werden',
    name: 'ArbeitgeberWerden',
    beforeEnter: isInterest,
    component: EmployerRegistration
  },
  {
    path: '/buchung',
    name: 'Booking',
    beforeEnter: isPartner,
    component: PartnerBooking
  },
  {
    path: '/partner-transaktionen',
    name: 'PartnerTransactions',
    beforeEnter: isPartnerOrEmployer,
    component: PartnerTransactions
  },
  {
    path: '/partner-auswertungen',
    name: 'PartnerStatistics',
    beforeEnter: isPartnerOrEmployer,
    component: PartnerStatistics
  },
  {
    path: '/auftraggeber-auswertungen',
    name: 'ContractorStatistics',
    // beforeEnter: isPartnerOrEmployer,
    component: ContractorStatistics
  },
  {
    path: '/demo-auswertungen',
    name: 'InterestDemoStatistics',
    beforeEnter: isInterest,
    component: InterestDemoStatistics
  },
  {
    path: '/edit-logo',
    name: 'EditLogo',
    beforeEnter: isPartnerOrEmployer,
    component: EditLogo
  },
  {
    path: '/dokumente',
    name: 'PartnerEmployerDocuments',
    beforeEnter: isPartnerOrEmployer,
    component: PartnerEmployerDocuments
  },
  {
    path: '/demo-buchung',
    name: 'DemoBooking',
    beforeEnter: isInterest,
    component: InterestDemoBooking
  },
  {
    path: '/demo-transaktionen',
    name: 'DemoTransactions',
    beforeEnter: isInterest,
    component: InterestDemoTransactions
  },
  {
    path: '/wartungsarbeiten',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/mitarbeitercards-verwalten',
    name: 'EmployerManageCards',
    beforeEnter: isEmployer,
    component: EmployerManageCards
  },
  {
    path: '/abfrage',
    name: 'Abfrage',
    component: Abfrage
  },
  {
    path: '/push-notifications',
    name: 'PushNotifications',
    beforeEnter: isTrolleymaker,
    component: PushNotifications
  },
  {
    path: '/password-reset-list',
    name: 'PasswordResetList',
    beforeEnter: isTrolleymaker,
    component: PasswordResetList
  },
  {
    path: '/check-balance-link-generator',
    name: 'CheckBalanceLinkGenerator',
    beforeEnter: isTrolleymaker,
    component: CheckBalanceLinkGenerator
  },
  {
    path: '/potential-partners-link-generator',
    name: 'PotentialPartnersLinkGenerator',
    beforeEnter: isTrolleymaker,
    component: PotentialPartnersLinkGenerator
  }

  /*
  {
    path: '/partner-arbeitgeber-werden',
    name: 'PartnerToEmployerRegistration',
    beforeEnter: isPartner,
    component: PartnerToEmployerRegistration
  }
  */
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  store.commit('SET_LOADING_STATUS', false)

  if (to.name !== 'Login' && to.name !== 'PartnerLogin' && to.name !== 'EmployerLogin' && to.name !== 'ContractorLogin' && to.name !== 'TrolleymakerportalLogin' && to.name !== 'PartnerPersonalData' && to.name !== 'ResetPassword') {
    store.commit('DELETE_ALERTS')
  }

  if (to.name === 'Login' || to.name === 'Registration' || to.name === 'ResetPassword' || to.name === 'NewPassword' || to.name === 'PartnerLogin' || to.name === 'EmployerLogin' || to.name === 'InterestRegistration') {
    if (to.query && to.query.region && to.query.region !== '') {
      /* const region = {}
      region.name = to.query.region
      region.primaryColor = Helper.getPrimaryColorForRegion(to.query.region)
      region.cardName = Helper.getCardNameForRegion(to.query.region)
      region.whiteLabelWebsiteUrl =
      region.whiteLabelWebsiteDomain = */
      // const region = Helper.getRegionObjectForSlug(to.query.region)
      // store.commit('SET_REGION', region)

      await store.dispatch('getRegionData', to.query.region)
        .then(response => { })
        .catch(() => {
          // store.commit('ADD_ALERT', { category: 'Fehler', message: error })
        })
    }
  }
})

router.afterEach((to, from) => {
  if (document.getElementById('navbar-toggler-button') !== null) {
    document.getElementById('navbar-toggler-button').classList.remove('form-element-danger')
  }

  if (document.getElementById('mainMenu') !== null) {
    document.getElementById('mainMenu').classList.remove('show')
  }
})

export default router
