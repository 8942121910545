<template>
 <div class="container py-1 py-md-5 h-75">
  <div class="content pt-0 pt-sm-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6">
        <div v-if="this.isValidRequest !== undefined && this.isValidRequest === false" class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <h1>Ungültiger Seitenaufruf</h1>
        </div>
        <div v-if="this.isValidRequest && balance !== undefined" class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <h1>Guthaben für {{ cardID }} beträgt {{ balance.balanceFormattedDE }}€</h1>
        </div>
        <div v-if="!isLoggedIn && !this.isCardRegistered" class="row mt-3 justify-content-center justify-content-lg-start text-center text-lg-start">
          <router-link :to="{ name: 'Registration' }" class="btn btn-primary">Jetzt Karte registrieren &gt;&gt;</router-link>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
export default {
  name: 'AbfragePage',
  data () {
    return {
      isValidRequest: undefined,
      balance: undefined,
      cardID: undefined,
      isCardRegistered: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.loginStatus
    }
  },
  created () {
    if (this.$route.query && this.$route.query.x !== undefined && this.$route.query.x !== '') {
      this.$store.commit('SET_LOADING_STATUS', true)
      const x = this.$route.query.x
      this.$store.dispatch('abfrage', { x }).then(response => {
        this.balance = response.balance
        this.cardID = response.cardID
        this.isValidRequest = true
        if (typeof response.region_name !== 'undefined') {
          this.$store.dispatch('getRegionData', response.region_name)
            .then(response => { })
            .catch(() => {
            })
        }
        if (typeof response.isCardRegistered !== 'undefined' && response.isCardRegistered === true) {
          this.isCardRegistered = true
        }
        this.$store.commit('SET_LOADING_STATUS', false)
      }).catch(() => {
        this.isValidRequest = false
        this.balance = undefined
        this.$store.commit('SET_LOADING_STATUS', false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .vue_client_recaptcha {
    justify-content: flex-start;
  }

  .input-group-text {
    border-radius: 0;
  }

  #changePasswordType {
    cursor: pointer;
  }
</style>
