<template>
  <div class="content">
    <div class="container py-1 py-md-5">
      <div class="row mt-5 g-0">
        <div class="col-12 col-lg-4 card-primary-background-color text-white rounded-start p-4">
          <h2 class="text-white">FAQ</h2>
          <p>Sie haben Fragen rund um die MitarbeiterCARD? In unseren FAQ finden Sie die Antwort auf die meisten Fragen.</p>
          <p>Wurde ihre Frage nicht beantwortet? Dann können Sie sich über das Kontaktformular gerne an uns wenden.</p>
        </div>
        <div class="col-12 col-lg-8">
          <img class="img-fluid" :src="require('../assets/img/employer-info/personen-daumen-nach-oben.jpg')">
        </div>
      </div>
      <div class="row my-5">
        <div class="col-12">
          <div class="accordion" id="accordionFAQ">
            <div v-for="(faq, index) in employerFAQs" :key="index">
              <div class="accordion-item">
                <h2 class="accordion-header" :id="`heading${index}`">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" :aria-expanded="index === 0? 'true' : 'false'" :aria-controls="`collapse${index}`">
                    {{ faq.question }}
                  </button>
                </h2>
                <div :id="`collapse${index}`" class="accordion-collapse collapse" :class="index === 0? 'show' : ''" :aria-labelledby="`heading${index}`" data-bs-parent="#accordionFAQ">
                  <div class="accordion-body">
                    <div v-html="faq.answer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-full-width py-1 py-md-5 bg-light-gray" v-if="isInterested">
        <div class="container">
          <div class="row mt-5">
            <div class="col-12">
              <h1><span class="fw-bold">Sie haben noch eine Frage?</span> - Schreiben Sie uns!</h1>
              <div class="mt-0 mt-sm-3">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <label for="inputFirstName" class="form-label label-view">
                      Firma
                    </label>
                    <input type="text" id="inputCompanyName" class="form-control" v-model="contactFormData.companyName" placeholder="Firmennamen eingeben">
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-6">
                    <label for="inputTitle" class="form-label label-view">
                      Anrede
                    </label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select class="form-select input-view" v-model="contactFormData.salutation" id="inputSalutation">
                          <option value="Herr">Herr</option>
                          <option value="Frau">Frau</option>
                          <option value="Divers">Divers</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-6">
                    <label for="inputFirstName" class="form-label label-view">
                      Name*
                    </label>
                    <input type="text" id="inputName" class="form-control" v-model="contactFormData.name" placeholder="Namen eingeben" required>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-12 col-lg-6">
                    <label for="inputMail" class="form-label label-view">
                      E-Mail-Adresse*
                    </label>
                    <input type="email" id="inputMail" class="form-control" v-model="contactFormData.email" placeholder="E-Mail-Adresse eingeben" required>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-12 col-lg-6">
                    <label for="inputMail" class="form-label label-view">
                      Telefonnummer
                    </label>
                    <input type="text" id="inputPhone" class="form-control" v-model="contactFormData.phone" placeholder="Telefonnummer eingeben">
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-6">
                    <label for="inputTitle" class="form-label label-view">
                      Bitte wählen Sie ein Thema aus*
                    </label>
                    <div class="row">
                      <div class="col-lg-6">
                        <select class="form-select input-view" v-model="contactFormData.subject" id="inputSubject">
                          <option value="Partnerkonto/Buchungserfassung">Partnerkonto/Buchungserfassung</option>
                          <option value="Abrechnung/Rechnung">Abrechnung/Rechnung</option>
                          <option value="Sonstiges">Sonstiges</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-12">
                    <label for="inputMessage" class="form-label">Mitteilung*</label>
                    <textarea class="form-control" id="inputMessage" v-model="contactFormData.message" rows="7" placeholder="Nachricht eingeben"></textarea>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-12">
                    Die Übermittlung der Daten erfolgt über eine verschlüsselte Verbindung. Wir verwenden Ihre Daten ausschließlich zur Bearbeitung Ihrer Anfrage. Weitere Informationen zur Verarbeitung Ihrer Daten und Widerrufshinweise finden Sie in der Datenschutzerklärung dieser Webseite. <router-link :to="{ name: 'DataProtection' }">Die Datenschutzerklärung finden Sie hier</router-link>.
                  </div>
                </div>
                <div class="mt-4 mt-sm-2">
                  <button type="submit" @click="sendContactForm" class="btn btn-primary">Senden &gt;&gt;</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserRoles from '../enums/UserRoles'

export default {
  name: 'EmployerInfoPage',
  data () {
    return {
      employerFAQs: [],
      contactPerson: '',
      email: '',
      phone: '',
      contactFormData: {
        salutation: '',
        name: '',
        companyName: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      }
    }
  },
  methods: {
    async fetchEmployerFAQs () {
      this.$store.commit('SET_LOADING_STATUS', true)
      let requestToDispatch = 'getEmployerFAQs'
      if (this.isInterested) {
        requestToDispatch = 'getInterestEmployerFAQs'
      }
      await this.$store.dispatch(requestToDispatch, { regionID: this.$store.state.region.id }).then(response => {
        if (this.isInterested) {
          this.employerFAQs = response.acf.interest_employer_faqs
        } else {
          this.employerFAQs = response.acf.employer_faqs
        }
        this.contactPerson = response.acf.contact_person
        this.email = response.acf.email
        this.phone = response.acf.phone
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    sendContactForm () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('interestContactForm', { salutation: this.contactFormData.salutation, name: this.contactFormData.name, companyName: this.contactFormData.companyName, contactEmail: this.contactFormData.email, phone: this.contactFormData.phone, subject: this.contactFormData.subject, message: this.contactFormData.message })
        .then(() => {
          // clear form
          this.contactFormData.salutation = ''
          this.contactFormData.name = ''
          this.contactFormData.companyName = ''
          this.contactFormData.email = ''
          this.contactFormData.phone = ''
          this.contactFormData.subject = ''
          this.contactFormData.message = ''
        }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  },
  computed: {
    isInterested () {
      return this.$store.state.user.role === UserRoles.INTERESTED
    }
  },
  created () {
    this.fetchEmployerFAQs()
  }
}
</script>
