<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row">
      <div class="col-12">
        <h1>GGUIDs <span class="fw-bold"> von potentiellen Partnern hochladen & Interessentenregistrierungs-Link generieren</span></h1>
        <strong>Beispiel der hochzuladenden CSV Datei:</strong><br />
        <span class="fst-italic">(CSV-Datei mit nur einer Spalte mit den GGUIDs der potentiallen Partnern Firmendatensätzen und <strong>ohne</strong> erste Zeile Tabellenkopf)</span>
      </div>
      <div class="col-auto">
        <table class="table table-bordered">
          <tr>
            <td>9D465F0F2EC34CEEB06F1FCD7FD21DF1</td>
          </tr>
          <tr>
            <td>9E279909412D4B1A807B87F7A296FD15</td>
          </tr>
          <tr>
            <td>12658577D894454794A1163E6BAEFD64</td>
          </tr>
          <tr>
            <td>.....</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8 pt-2">
        <label for="inputLogo" class="form-label label-view fw-bold fs-3">
          CSV-Datei mit Liste der GGUIDs der Firmendatensätze hochladen
        </label>
        <input type="file" class="form-control" @change="onPotentialPartnersListFileSet" ref="inputPotentialPartnersListFile" accept=".csv">
      </div>
      <div class="col-12 col-lg-8">
        <div class="text-end mt-4 mt-sm-2">
          <button type="submit" @click="sendUploadPotentialPartnersListFile()" class="btn btn-primary">GGUIDs hochladen & Potentielle Partner Interessentenregistrierungslinks generieren >></button>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>

export default {
  name: 'CheckBalanceLinkGeneratorPage',
  data () {
    return {
      potentialPartnersListFile: undefined
    }
  },
  methods: {
    onPotentialPartnersListFileSet (event) {
      this.potentialPartnersListFile = event.target.files[0]
    },
    async sendUploadPotentialPartnersListFile () {
      if (!this.potentialPartnersListFile) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Datei zum Hochladen angegeben.' })
        return
      }

      this.$store.commit('DELETE_ALERTS')

      this.$store.commit('SET_LOADING_STATUS', true)
      const formData = new FormData()
      formData.append('potentialPartnersListFile', this.potentialPartnersListFile)
      this.$store.dispatch('uploadGeneratePotentialPartnersRegistrationURLs', formData)
        .then(() => {
          // clear form
          this.potentialPartnersListFile = undefined
          this.$refs.inputPotentialPartnersListFile.value = null
        }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  }
}
</script>
