<template>
  <div class="container py-1 py-md-5">
    <div class="content">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-4 text-center mb-5">
          <a href="/login">
            <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid" />
          </a>
        </div>
        <div class="col-12 col-lg-8">
          <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
            <h1><span class="fw-bold">Arbeitgeberdaten</span> bearbeiten</h1>
            <p>Bitte ergänzen Sie die fehlenden Informationen.</p>
            <div v-if="this.registrationData.partnerDataComplete == false" class="alert alert-danger" role="alert">
              <u>Wichtiger Hinweis:</u> Bitte vervollständigen Sie die Arbeitgeberinformationen!
            </div>
            <div class="btn-group mb-3" role="group" aria-label="Schritte">
              <button type="button" class="btn" :class="currentStep === 1 ? 'btn-primary' : 'btn-outline-secondary'" @click="toStep(1)">1. Vertrags- &amp; Rechnungsdaten</button>
              <button type="button" class="btn" :class="currentStep === 2 ? 'btn-primary' : 'btn-outline-secondary'" @click="toStep(2)">2. Zahlungsart</button>
              <button type="button" class="btn" :class="currentStep === 3 ? 'btn-primary' : 'btn-outline-secondary'" @click="toStep(3)">3. Vertragsdetails</button>
            </div>
            <div v-if="currentStep === 1">
              <h3>Vertragsdaten</h3>
              <div class="pt-2">
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyName" class="form-label label-view">Firmenname (ggf. mit Rechtsform)</label>
                      <input v-model="registrationData.companyName" type="text" id="companyName" class="form-control input-view" placeholder="Firmennamen eingeben">
                      <div id="companyNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyAddressAdditional" class="form-label label-view">Adresse-Zusatz</label>
                      <input v-model="registrationData.companyAddressAdditional" type="text" id="companyAddressAdditional" class="form-control input-view" placeholder="Adress-Zusatz eingeben (optional)">
                      <div id="companyAddressAdditionalNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyStreet" class="form-label label-view">Straße und Hausnummer</label>
                      <input v-model="registrationData.companyStreet" type="text" id="companyStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                      <div id="companyStreetNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyZip" class="form-label label-view">Postleitzahl</label>
                      <input v-model="registrationData.companyZip" type="text" id="companyZip" class="form-control input-view" placeholder="PLZ eingeben">
                      <div id="companyZipNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyCity" class="form-label label-view">Ort</label>
                      <input v-model="registrationData.companyCity" type="text" id="companyCity" class="form-control input-view" placeholder="Ort eingeben">
                      <div id="companyCityNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyCountry" class="form-label label-view">Land</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.companyCountry" class="form-select input-view" id="companyCountry">
                            <option value="DE">Deutschland</option>
                            <option value="CH">Schweiz</option>
                            <option value="AT">Österreich</option>
                            <option value="FR">Frankreich</option>
                            <option value="BE">Belgien</option>
                            <option value="LU">Luxemburg</option>
                            <option value="DK">Dänemark</option>
                            <option value="NO">Norwegen</option>
                            <option value="SE">Schweden</option>
                            <option value="FI">Finnland</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="NL">Niederlande</option>
                            <option value="PL">Polen</option>
                            <option value="PT">Portugal</option>
                            <option value="RO">Rumänien</option>
                            <option value="RU">Russland</option>
                            <option value="GR">Griechenland</option>
                            <option value="AL">Albanien</option>
                            <option value="AD">Andorra</option>
                            <option value="BG">Bulgarien</option>
                            <option value="IT">Italien</option>
                            <option value="IE">Irland</option>
                            <option value="EE">Estland</option>
                            <option value="LV">Lettland</option>
                            <option value="LT">Litauen</option>
                            <option value="KZ">Kasachstan</option>
                            <option value="SM">San Marino</option>
                            <option value="RS">Serbien</option>
                            <option value="SK">Slowakei</option>
                            <option value="SI">Slowenien</option>
                            <option value="ES">Spanien</option>
                            <option value="CZ">Tschechien</option>
                            <option value="TR">Türkei</option>
                            <option value="UA">Ukraine</option>
                            <option value="HU">Ungarn</option>
                            <option value="GB">Vereinigtes Königreich</option>
                            <option value="MT">Malta</option>
                            <option value="MC">Monaco</option>
                            <option value="MK">Nordmazedonien</option>
                          </select>
                        </div>
                      </div>
                      <div id="companyCountryNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top" v-if="this.$store.state.region.name !== 'Leinfelden-Echterdingen'">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyGewerbeverein" class="form-label label-view">Mitglied Gewerbeverein (Info)</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.companyGewerbeverein" class="form-select input-view" id="companyGewerbeverein" readonly="readonly" disabled="disabled">
                            <option v-for="vereinsname in regionCheckoutData.requirement_to_join_club_names" :key="vereinsname.requirement_to_join_club_name" :value="vereinsname.requirement_to_join_club_name">{{ vereinsname.requirement_to_join_club_name }}</option>
                            <option value="Kein Mitglied, möchte aber Infos">Kein Mitglied, möchte aber Infos</option>
                            <option value="Kein Mitglied">Kein Mitglied</option>
                          </select>
                        </div>
                      </div>
                      <div id="companyGewerbevereinNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyEmail" class="form-label label-view">E-Mail-Adresse (Vertrag)</label>
                      <input v-model="registrationData.companyEmail" type="email" id="companyEmail" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="70">
                      <div id="companyEmailNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyEmailRepeated" class="form-label label-view">E-Mail-Adresse (Vertrag) wiederholen</label>
                      <input v-model="registrationData.companyEmailRepeated" type="email" id="companyEmailRepeated" class="form-control input-view" placeholder="E-Mail-Adresse wiederholen" maxlength="100">
                      <div id="companyEmailRepeatedNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyEmailHeadquarter" class="form-label label-view">E-Mail-Adresse (Zentrale)</label>
                      <input v-model="registrationData.companyEmailHeadquarter" type="email" id="companyEmailHeadquarter" class="form-control input-view" placeholder="E-Mail-Adresse (Zentrale) eingeben" maxlength="70">
                      <div id="companyEmailHeadquarterNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyEmailHeadquarterRepeated" class="form-label label-view">E-Mail-Adresse (Zentrale) wiederholen</label>
                      <input v-model="registrationData.companyEmailHeadquarterRepeated" type="email" id="companyEmailHeadquarterRepeated" class="form-control input-view" placeholder="E-Mail-Adresse (Zentrale) wiederholen" maxlength="100">
                      <div id="companyEmailHeadquarterRepeatedNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <br />
                <h3>Rechnungsdaten</h3>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyREName" class="form-label label-view">Firmenname (ggf. mit Rechtsform)</label>
                      <input v-model="registrationData.companyREName" type="text" id="companyREName" class="form-control input-view" placeholder="Firmennamen eingeben">
                      <div id="companyRENameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyREStreet" class="form-label label-view">Straße und Hausnummer</label>
                      <input v-model="registrationData.companyREStreet" type="text" id="companyREStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                      <div id="companyREStreetNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyREZIP" class="form-label label-view">Postleitzahl</label>
                      <input v-model="registrationData.companyREZip" type="text" id="companyREZip" class="form-control input-view" placeholder="PLZ eingeben">
                      <div id="companyREZipNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyRECity" class="form-label label-view">Ort</label>
                      <input v-model="registrationData.companyRECity" type="text" id="companyRECity" class="form-control input-view" placeholder="Ort eingeben">
                      <div id="companyRECityNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyRECountry" class="form-label label-view">Land</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.companyRECountry" class="form-select input-view" id="companyRECountry">
                            <option value="Deutschland">Deutschland</option>
                            <option value="Schweiz">Schweiz</option>
                            <option value="Österreich">Österreich</option>
                            <option value="Frankreich">Frankreich</option>
                            <option value="Belgien">Belgien</option>
                            <option value="Luxemburg">Luxemburg</option>
                            <option value="Dänemark">Dänemark</option>
                            <option value="Norwegen">Norwegen</option>
                            <option value="Schweden">Schweden</option>
                            <option value="Finnland">Finnland</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Niederlande">Niederlande</option>
                            <option value="Polen">Polen</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Rumänien">Rumänien</option>
                            <option value="Russland">Russland</option>
                            <option value="Griechenland">Griechenland</option>
                            <option value="Albanien">Albanien</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Bulgarien">Bulgarien</option>
                            <option value="Italien">Italien</option>
                            <option value="Irland">Irland</option>
                            <option value="Estland">Estland</option>
                            <option value="Lettland">Lettland</option>
                            <option value="Litauen">Litauen</option>
                            <option value="Kasachstan">Kasachstan</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Serbien">Serbien</option>
                            <option value="Slowakei">Slowakei</option>
                            <option value="Slowenien">Slowenien</option>
                            <option value="Spanien">Spanien</option>
                            <option value="Tschechien">Tschechien</option>
                            <option value="Türkei">Türkei</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="Ungarn">Ungarn</option>
                            <option value="Vereinigtes Königreich">Vereinigtes Königreich</option>
                            <option value="Malta">Malta</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Nordmazedonien">Nordmazedonien</option>
                          </select>
                        </div>
                      </div>
                      <div id="companyRECountryNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyREEmail" class="form-label label-view">E-Mail-Adresse (Rechnung)</label>
                      <input v-model="registrationData.companyREEmail" type="email" id="companyREEmail" class="form-control input-view" placeholder="Rechnungs-E-Mail-Adresse eingeben" maxlength="70">
                      <div id="companyREEmailNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyREEmailRepeated" class="form-label label-view">E-Mail-Adresse (Rechnung) wiederholen</label>
                      <input v-model="registrationData.companyREEmailRepeated" type="email" id="companyREEmailRepeated" class="form-control input-view" placeholder="Rechnungs-E-Mail-Adresse wiederholen" maxlength="100">
                      <div id="companyREEmailRepeatedNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <br />
                <h3>Inhaber / Geschäftsführer</h3>
                <span class="fst-italic">Dies sind keine Login-Daten und dienen nur zur Information.</span>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCeoName" class="form-label label-view">Vorname Nachname</label>
                      <input v-model="registrationData.ceoName" type="text" id="ceoName" class="form-control input-view" placeholder="Vorname Nachname des/r Geschäftsführer/in" maxlength="30">
                      <div id="ceoNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCeoPhone" class="form-label label-view">Telefon (Inhaber/Geschäftsführer)</label>
                      <input v-model="registrationData.ceoPhone" type="text" id="ceoPhone" class="form-control input-view" placeholder="Telefonnummer des/r Geschäftsführer/in" maxlength="30">
                      <div id="ceoPhoneNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <br />
                <h3>Ansprechpartner (Admin &amp; Login der Firma)</h3>
                <span class="fst-italic">Für Änderung der E-Mail-Adresse wenden Sie sich bitte an den Support.</span>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputContactPersonGender" class="form-label label-view">Geschlecht</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.contactPersonGender" class="form-select input-view" id="contactPersonGender">
                            <option value="männlich">männlich</option>
                            <option value="weiblich">weiblich</option>
                            <option value="divers">divers</option>
                            <option value="sonstige">sonstige</option>
                          </select>
                        </div>
                      </div>
                      <div id="contactPersonGenderNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputContactPersonFirstName" class="form-label label-view">Vorname</label>
                      <input v-model="registrationData.contactPersonFirstName" type="text" id="contactPersonFirstName" class="form-control input-view" placeholder="Vorname eingeben" maxlength="30">
                      <div id="contactPersonFirstNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputContactPersonLastName" class="form-label label-view">Nachname</label>
                      <input v-model="registrationData.contactPersonLastName" type="text" id="contactPersonLastName" class="form-control input-view" placeholder="Nachname eingeben" maxlength="30">
                      <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputContactPersonLastName" class="form-label label-view">E-Mail</label>
                      <input v-model="registrationData.contactPersonEmail" type="text" id="contactPersonEmail" class="form-control input-view" maxlength="30" readonly>
                      <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <br />
                <h3>Infos zur MitarbeiterCARD</h3>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="amountOfEmployerCards" class="form-label label-view">Anzahl MitarbeiterCARDs</label>
                      <input v-model="registrationData.amountOfEmployerCards" type="number" id="amountOfEmployerCards" class="form-control input-view" placeholder="Anzahl MitarbeiterCARDs eingeben" :disabled="(this.registrationData.partnerDataComplete === true)" :readonly="(this.registrationData.partnerDataComplete === true)">
                      <div id="amountOfEmployerCardsNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <div class="form-check">
                        <input v-model="registrationData.wantIndividualEmployerCards" class="form-check-input" type="checkbox" id="wantIndividualEmployerCards" :disabled="(this.registrationData.partnerDataComplete === true)" :readonly="(this.registrationData.partnerDataComplete === true)">
                        <label class="form-check-label" for="wantIndividualEmployerCards">
                          Firmenlogo* auf Ihre MitarbeiterCARDs
                        </label>
                      </div>
                      <span>*Aufpreis je CARD (<router-link :to="{ name: 'EmployerConditionsOverview' }">s. aktuelle Preisliste</router-link>)</span>
                      <div id="wantIndividualEmployerCardsNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="employerCardStartDate" class="form-label label-view">Startzeitpunkt (erster Beladungsmonat) MitarbeiterCARDs</label>
                      <input v-model="registrationData.employerCardStartDate" type="date" id="employerCardStartDate" class="form-control input-view" placeholder="Gewünschten Startzeitpunkt eingeben" min="2022-12-01" pattern="\d{4}-\d{2}-\d{2}" :disabled="(this.registrationData.partnerDataComplete === true)" :readonly="(this.registrationData.partnerDataComplete === true)">
                      <div id="employerCardStartDateNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="employerCardsLoadingRhythm" class="form-label label-view">Bitte geben Sie Ihren gewünschten Beladungsrhythmus an</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.employerCardsLoadingRhythm" class="form-select input-view" id="employerCardsLoadingRhythm" :disabled="(this.registrationData.partnerDataComplete === true)" :readonly="(this.registrationData.partnerDataComplete === true)">
                            <option>Monatlich</option>
                            <option>Vierteljährlich</option>
                            <option>Halbjährlich</option>
                            <option>Jährlich</option>
                            <option>Unregelmäßig</option>
                          </select>
                        </div>
                      </div>
                      <div id="employerCardsLoadingRhythmNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="pt-2 mb-3 border-top">
                  <div class="text-end">
                    <button v-if="this.registrationData.partnerDataComplete === true" @click="saveData()" class="btn btn-primary text-end mx-3">Arbeitgeberdaten speichern &gt;&gt;</button>
                    <button @click="nextStep()" class="btn btn-primary ms-4">Zahlungsart &gt;&gt;</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="(currentStep === 2)">
              <h2>Zahlungsart</h2>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputPaymentMethod" class="form-label label-view">Zahlungsart / Art der Abrechnung</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select v-model="registrationData.paymentMethod" @change="paymentMethodChanged()" class="form-select input-view" id="paymentMethod" :disabled="this.paymentMethodAlreadySet" :readonly="this.paymentMethodAlreadySet">
                          <option value="Bankeinzug">Bankeinzug</option>
                          <option value="Rechnung">Rechnung</option>
                        </select>
                      </div>
                    </div>
                    <div id="paymentMethodNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="employer" class="form-label label-view">Bitte geben Sie Ihren gewünschten Beladungstermin an</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select v-model="registrationData.employerCardsLoadingDate" class="form-select input-view" id="employerCardsLoadingDate" :disabled="(this.registrationData.partnerDataComplete === true)" :readonly="(this.registrationData.partnerDataComplete === true)">
                          <option :disabled="registrationData.paymentMethod === 'Rechnung' && registrationData.community !== 'Leinfelden-Echterdingen' && registrationData.community !== 'Landshut' && registrationData.community !== 'Wunsiedel'">Anfang des Monats</option>
                          <option :disabled="registrationData.paymentMethod === 'Rechnung' && registrationData.community !== 'Leinfelden-Echterdingen' && registrationData.community !== 'Landshut' && registrationData.community !== 'Wunsiedel'">Mitte des Monats</option>
                          <option>Ende des Monats</option>
                          <option :disabled="registrationData.paymentMethod === 'Rechnung' && registrationData.community !== 'Leinfelden-Echterdingen' && registrationData.community !== 'Landshut' && registrationData.community !== 'Wunsiedel'">Individuelle Termine</option>
                        </select>
                      </div>
                    </div>
                    <div id="employerCardsLoadingDateNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div v-if="registrationData.paymentMethod === 'Bankeinzug'">
                <h4 class="mt-3">SEPA-Mandat (Firmenlastschrift)</h4>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputSepaCompanyStreet" class="form-label label-view">Straße und Hausnummer</label>
                      <input v-model="registrationData.sepaCompanyStreet" type="text" id="sepaCompanyStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                      <div id="sepaCompanyStreetNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputSepaCompanyZip" class="form-label label-view">Postleitzahl</label>
                      <input v-model="registrationData.sepaCompanyZip" type="text" id="sepaCompanyZip" class="form-control input-view" placeholder="PLZ eingeben">
                      <div id="sepaCompanyZipNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputSepaCompanyCity" class="form-label label-view">Ort</label>
                      <input v-model="registrationData.sepaCompanyCity" type="text" id="sepaCompanyCity" class="form-control input-view" placeholder="Ort eingeben">
                      <div id="sepaCompanyCityNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputSepaMandateReferenceNumber" class="form-label label-view">Mandatsreferenznummer</label>
                      <input v-model="registrationData.sepaMandateReferenceNumber" type="text" id="sepaMandateReferenceNumber" class="form-control input-view" placeholder="Mandatsrefernznummer eingeben" maxlength="70" disabled readonly>
                      <div id="sepaMandateReferenceNumberNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <br />
                <br />
                <h4>Bankverbindung {{ registrationData.paymentMethod === 'Rechnung' ? '(Wohin dürfen wir ihre Gutschriften überweisen)' : ''}}</h4>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputSepaAccountHolder" class="form-label label-view">Name Kontoinhaber</label>
                      <input v-model="registrationData.sepaAccountHolder" type="text" id="sepaAccountHolder" class="form-control input-view" placeholder="Name Kontoinhaber eingeben">
                      <div id="sepaAccountHolderNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputSepaIBAN" class="form-label label-view">IBAN</label>
                      <input v-model="registrationData.sepaIBAN" type="text" id="sepaIBAN" class="form-control input-view" placeholder="IBAN eingeben">
                      <div id="sepaIBANNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputSepaBIC" class="form-label label-view">BIC</label>
                      <input v-model="registrationData.sepaBIC" type="text" id="sepaBIC" class="form-control input-view" placeholder="BIC eingeben">
                      <div id="sepaBICNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputSepaBankName" class="form-label label-view">Name der Bank</label>
                      <input v-model="registrationData.sepaBankName" type="text" id="sepaBankName" class="form-control input-view" placeholder="Name der Bank eingeben">
                      <div id="sepaBankNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div v-if="registrationData.paymentMethod === 'Bankeinzug'">
                <button @click="generateSepaPDF()" class="btn btn-primary">SEPA-Formular drucken</button>
                <br />
                <br />
                <div class="alert alert-danger" role="alert">
                  Achtung, wichtiger Hinweis !<br />
                  Bitte drucken Sie das SEPA-Mandat in doppelter Ausfertigung aus. Unterschreiben Sie dieses und senden Sie uns ein Exemplar zu.
                  Das zweite Exemplar reichen Sie bitte unverzüglich bei Ihrer Hausbank ein, da nur so der reibungslose Ablauf gewährleistet ist.
                  Ggfs. in dieser Zeit erstellte Rechnungen/Abrechnungen überweisen Sie dann bitte. Ein entsprechender Hinweis ist auf dem Abrechnungsbeleg dann vorhanden.
                  Frühstens eine Woche nachdem uns Ihr SEPA-Mandat vorliegt, werden wir das Einzugsverfahren dann aktivieren.<br />
                  Für Rücklastschriften, die mangels hinterlegtem SEPA-Firmenlastschrift-Mandats entstehen, müssen wir je Rücklastschrift leider 20,- EUR berechnen.
                </div>
              </div>
              <div class="pt-2 mb-3 border-top">
                <div class="float-start">
                  <button @click="previousStep()" class="btn btn-primary text-start">&lt;&lt; Zurück zu Vertrags- & Rechnungsdaten</button>
                </div>
                <div class="float-end">
                  <button v-if="this.registrationData.partnerDataComplete === true" @click="saveData()" class="btn btn-primary text-end me-3">Arbeitgeberdaten speichern &gt;&gt;</button>
                  <button @click="nextStep()" class="btn btn-primary text-end">Vertragsdetails &gt;&gt;</button>
                </div>
              </div>
            </div>
            <div v-if="(currentStep === 3)">
              <h2>Vertragsdetails</h2>
              <div class="mb-3 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Vertragsnummer</td>
                          <td>{{ registrationData.contractID }}</td>
                        </tr>
                        <tr>
                          <td>Vertragsdaten übermittelt am</td>
                          <td>{{ registrationData.contactDetailsSentAt }}</td>
                        </tr>
                        <tr>
                          <td>Vertragsbeginn</td>
                          <td>{{ registrationData.contractStartAt }}</td>
                        </tr>
                        <tr>
                          <td>Vertragsstatus</td>
                          <td>{{ registrationData.contractStatus }}</td>
                        </tr>
                        <tr>
                          <td>Anzahl MitarbeiterCARDs</td>
                          <td>{{ registrationData.amountOfEmployerCards ? registrationData.amountOfEmployerCards : '-' }}</td>
                        </tr>
                        <tr>
                          <td>Starttermin (erster Beladungsmonat)</td>
                          <td>{{ registrationData.employerCardStartDate ? this.formattedEmployerCardStartDate : '-' }}</td>
                        </tr>
                        <tr>
                          <td>Beladungsrhythmus</td>
                          <td>{{ registrationData.employerCardsLoadingRhythm ? registrationData.employerCardsLoadingRhythm : '-' }}</td>
                        </tr>
                        <tr>
                          <td>AGB</td>
                          <td><a :href="`${this.$store.state.region.whiteLabelWebsiteUrl}partner-bedingungen/`" target="_blank">Zu den AGBs</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                  <p>Im Rahmen der Registrierung und Nutzung des Arbeitgeberportals verarbeiten wir Ihre Daten. Hinweise zur Verarbeitung und Nutzung finden Sie in unseren <router-link :to="{ name: 'PrivacyPolicy' }" target="_blank">Datenschutzhinweisen</router-link> und der <router-link :to="{ name: 'DataProtection' }" target="_blank">Datenschutzerkärung</router-link>.</p>
              </div>
              <div class="pt-2 mb-3 border-top">
                <div class="float-start">
                  <button @click="previousStep()" class="btn btn-primary text-start">&lt;&lt; Zurück zur Zahlungsart</button>
                </div>
                <div class="float-end">
                  <button @click="saveData()" class="btn btn-primary text-end">Arbeitgeberdaten speichern >></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const slugify = require('slugify')
const bootstrap = require('bootstrap')

export default {
  name: 'EmployerPersonalDataPage',
  data () {
    return {
      currentStep: 1,
      registrationData: {},
      regionCheckoutData: {},
      paymentMethodAlreadySet: false,
      validationStatus: false,
      paypalLoaded: false,
      deleteBranchBootstrapEl: undefined,
      registrationDataStep1Keys: ['companyEmailHeadquarter', 'companyEmailHeadquarterRepeated', 'companyEmail', 'companyEmailRepeated', 'companyName', 'companyAddressAdditional', 'companyStreet', 'companyZip', 'companyCity', 'companyCountry', 'contactPersonGender', 'contactPersonFirstName', 'contactPersonLastName', 'companyREName', 'companyREStreet', 'companyREZip', 'companyRECity', 'companyRECountry', 'companyREEmail', 'companyREEmailRepeated', 'amountOfEmployerCards', 'employerCardStartDate', 'employerCardsLoadingRhythm'],
      registrationDataStep2Keys: ['employerCardsLoadingDate', 'paymentMethod', 'sepaCompanyStreet', 'sepaCompanyZip', 'sepaCompanyCity', 'sepaMandateReferenceNumber', 'sepaAccountHolder', 'sepaIBAN', 'sepaBIC', 'sepaBankName']
    }
  },
  computed: {
    cardName () {
      return this.$store.state.region.cardName ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    },
    formattedEmployerCardStartDate () {
      if (this.registrationData.employerCardStartDate && this.registrationData.employerCardStartDate !== undefined) {
        const d = new Date(this.registrationData.employerCardStartDate)
        const yr = d.getFullYear()
        const mnt = d.getMonth() + 1 < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
        const day = d.getDate() < 9 ? '0' + d.getDate() : d.getDate()
        return day + '.' + mnt + '.' + yr
      } else {
        return '-'
      }
    }
  },
  methods: {
    async saveData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      if (await this.validateRegistrationData()) {
        this.saveRegistrationData()
      } else {
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    },
    async saveRegistrationData () {
      this.$store.dispatch('updateEmployerPersonalData', this.registrationData)
        .then(() => {
          this.$store.commit('SET_LOADING_STATUS', false)
        }).catch(() => {
          this.$store.commit('SET_LOADING_STATUS', false)
        })
    },
    async validateRegistrationData () {
      this.validationStatus = true
      if (this.currentStep === 1) {
        this.registrationDataStep1Keys.forEach(key => {
          document.getElementById(key).classList.remove('form-element-danger')
          document.getElementById(key + 'Note').innerHTML = ''
        })
        this.registrationDataStep1Keys.forEach(key => {
          if (key === 'contactPersonEmail') {
            return
          }
          if (!(key === 'companyAddressAdditional' || key === 'wantIndividualEmployerCards') && (!this.registrationData[key] || this.registrationData[key] === '')) {
            this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
            return
          }
          if (key !== 'companyAddressAdditional' && key !== 'contactPersonFirstName' && key !== 'contactPersonLastName' && key !== 'companyCountry' && key !== 'companyRECountry' && this.registrationData[key].length < 3) {
            this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
          }
          if (key === 'companyCountry' && this.registrationData[key].length < 2) {
            this.showValidationError(key, 'Ungültiger Ländercode. Bitte wenden Sie sich an den Support.')
          }
          if ((key === 'contactPersonGender' || key === 'contactPersonFirstName' || key === 'contactPersonLastName' || key === 'companyCity' || key === 'companyCountry' || key === 'contactPersonRole') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
          }
          if (key === 'companyStreet' && !(/\d/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
          }
          if ((key === 'companyZip' || key === 'amountOfEmployerCards') && !(/^\d+$/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Dieses Feld darf nur Ziffern enthalten.')
          }
          if ((key === 'companyZip') && !(this.registrationData[key].length === 5 || this.registrationData[key].length === 4)) {
            this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
          }
          if (key === 'amountOfEmployerCards' && this.registrationData.amountOfEmployerCards <= 0) {
            this.showValidationError(key, 'Es muss eine Anzahl größer 0 angegeben werden.')
          }
          if ((key === 'companyEmail' || key === 'companyEmailRepeated') && this.registrationData.companyEmail !== this.registrationData.companyEmailRepeated) {
            this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
          }
          if ((key === 'companyEmailHeadquarter' || key === 'companyEmailHeadquarterRepeated') && this.registrationData.companyEmailHeadquarter !== this.registrationData.companyEmailHeadquarterRepeated) {
            this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
          }
          if ((key === 'companyREEmail' || key === 'companyREEmailRepeated') && this.registrationData.companyREEmail !== this.registrationData.companyREEmailRepeated) {
            this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
          }
          if ((key === 'companyREEmail' || key === 'companyEmail' || key === 'companyEmailHeadquarter') && !this.registrationData[key].includes('@')) {
            this.showValidationError(key, 'Dies ist keine gültige E-Mail-Adresse.')
          }
          if (key === 'employerCardsLoadingRhythm' && !(this.registrationData.employerCardsLoadingRhythm === 'Monatlich' || this.registrationData.employerCardsLoadingRhythm === 'Vierteljährlich' || this.registrationData.employerCardsLoadingRhythm === 'Halbjährlich' || this.registrationData.employerCardsLoadingRhythm === 'Jährlich' || this.registrationData.employerCardsLoadingRhythm === 'Unregelmäßig')) {
            this.showValidationError(key, 'Dieses Feld enthält einen ungültigen Wert.')
          }
        })
      } else if (this.currentStep === 2) {
        if (!this.registrationData.paymentMethod || this.registrationData.paymentMethod === '') {
          document.getElementById('paymentMethod').classList.remove('form-element-danger')
          document.getElementById('paymentMethodNote').innerHTML = ''
        } else {
          this.registrationDataStep2Keys.forEach(key => {
            if (this.registrationData.paymentMethod === 'Bankeinzug') {
              document.getElementById(key).classList.remove('form-element-danger')
              document.getElementById(key + 'Note').innerHTML = ''
            }
          })
        }
        document.getElementById('employerCardsLoadingDate').classList.remove('form-element-danger')
        document.getElementById('employerCardsLoadingDateNote').innerHTML = ''
        this.registrationDataStep2Keys.forEach(key => {
          if (key === 'paymentMethod') {
            if (!this.registrationData[key] || this.registrationData[key] === '') {
              this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
              return
            } else {
              if (!(this.registrationData[key] === 'Bankeinzug' || this.registrationData[key] === 'Rechnung')) {
                this.showValidationError(key, 'Das Feld enthält einen ungültigen Wert.')
              }
            }
          }
          if (key === 'employerCardsLoadingDate') {
            if (!this.registrationData.employerCardsLoadingDate || this.registrationData.employerCardsLoadingDate === '') {
              this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
              return
            }
            if (!(this.registrationData.employerCardsLoadingDate === 'Anfang des Monats' || this.registrationData.employerCardsLoadingDate === 'Mitte des Monats' || this.registrationData.employerCardsLoadingDate === 'Ende des Monats' || this.registrationData.employerCardsLoadingDate === 'Individuelle Termine')) {
              this.showValidationError(key, 'Das Feld enthält einen ungültigen Wert.')
              return
            }
          }
          if (this.registrationData.paymentMethod && this.registrationData.paymentMethod === 'Bankeinzug') {
            if ((!this.registrationData[key] || this.registrationData[key] === '')) {
              this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
            } else {
              if (key === 'sepaAccountHolder' && this.registrationData[key].length < 3) {
                this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
              }
              if (key === 'sepaBIC' && this.registrationData[key].length < 5) {
                this.showValidationError(key, 'Der BIC ist ungültig.')
              }
              if (key === 'sepaIBAN' && this.registrationData.sepaIBAN.startsWith('DE') && this.registrationData.sepaIBAN.replace(/\s/g, '').length !== 22) {
                this.showValidationError(key, 'Die deutsche IBAN muss 22 Zeichen lang sein.')
              }
              if ((key === 'sepaCompanyCity') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.registrationData[key]))) {
                this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
              }
              if (key === 'sepaCompanyStreet' && !(/\d/.test(this.registrationData[key]))) {
                this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
              }
              if (key === 'sepaCompanyZip' && !(this.registrationData[key].length === 5 || this.registrationData[key].length === 4)) {
                this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
              }
            }
          }
        })
      }
      return this.validationStatus
    },
    showValidationError (key, validationMessage) {
      document.getElementById(key).classList.add('form-element-danger')
      document.getElementById(key + 'Note').innerHTML = validationMessage
      this.validationStatus = false
    },
    removeValidationError (key) {
      const element = document.getElementById(key)
      const elementNote = document.getElementById(key + 'Note')
      if (element) {
        element.classList.remove('form-element-danger')
      }
      if (elementNote) {
        elementNote.innerHTML = ''
      }
    },
    paymentMethodChanged () {
      if (this.registrationData.paymentMethod === 'Rechnung' && this.registrationData.employerCardsLoadingDate !== 'Ende des Monats') {
        this.registrationData.employerCardsLoadingDate = ''
      }
    },
    async fetchCompleteEmployerPersonalData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getCompleteEmployerPersonalData').then(response => {
        this.registrationData = response
        this.registrationData.sepaCompanyStreet = this.registrationData.companyREStreet
        this.registrationData.sepaCompanyZip = this.registrationData.companyREZip
        this.registrationData.sepaCompanyCity = this.registrationData.companyRECity
        if (Object.prototype.hasOwnProperty.call(this.registrationData, 'paymentMethod') && this.registrationData.paymentMethod !== '' && typeof this.registrationData.paymentMethod !== 'undefined' && this.registrationData.paymentMethod !== null) {
          this.paymentMethodAlreadySet = true
        }
      }).catch(() => {
        this.deactivateForm()
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async fetchRegionEmployerCheckoutData () {
      await this.$store.dispatch('getRegionEmployerCheckoutData', { regionName: this.$store.state.region.name, regionSlug: this.$store.state.region.slug }).then(response => {
        this.regionCheckoutData = response[0].acf
      }).catch(() => {
        this.deactivateForm()
      })
    },
    deactivateForm () {
      const inputs = document.querySelectorAll('.form-control, .form-select, .form-check-input, .btn')
      inputs.forEach(inputElement => {
        inputElement.readOnly = true
        inputElement.disabled = true
      })
    },
    async nextStep () {
      if (await this.validateRegistrationData()) {
        window.scrollTo(0, 1)
        this.currentStep = this.currentStep + 1
        this.$store.commit('DELETE_ALERTS')
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    async previousStep () {
      if (await this.validateRegistrationData()) {
        window.scrollTo(0, 1)
        this.currentStep = this.currentStep - 1
        this.paypalLoaded = false
        this.$store.commit('DELETE_ALERTS')
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    async toStep (toStepNumber) {
      if (await this.validateRegistrationData()) {
        if (this.registrationData.partnerDataComplete === true) {
          window.scrollTo(0, 1)
          this.currentStep = toStepNumber
          this.$store.commit('DELETE_ALERTS')
        } else {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ihre Arbeitgeberdaten wurden noch nicht vollständig ausgefüllt. Sie müssen zuerst alle Schritte nacheinander ausfüllen und anschließend speichern.' })
        }
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    async generateSepaPDF () {
      if (await this.validateRegistrationData()) {
        this.$store.commit('DELETE_ALERTS')
        const sepaData = {}
        sepaData.sepaMandateReferenceNumber = this.registrationData.sepaMandateReferenceNumber
        sepaData.sepaCompanyStreet = this.registrationData.sepaCompanyStreet
        sepaData.sepaCompanyZip = this.registrationData.sepaCompanyZip
        sepaData.sepaCompanyCity = this.registrationData.sepaCompanyCity
        sepaData.sepaAccountHolder = this.registrationData.sepaAccountHolder
        sepaData.sepaIBAN = this.registrationData.sepaIBAN
        sepaData.sepaBIC = this.registrationData.sepaBIC
        sepaData.sepaBankName = this.registrationData.sepaBankName

        this.$store.dispatch('generateSepaPDF', sepaData).then(response => {
          const blob = new Blob([response], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'trolleymaker-sepa-mandat.pdf'
          link.click()
        })
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    renderTooltips () {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      })
    }
  },
  created () {
    this.fetchCompleteEmployerPersonalData()
    this.fetchRegionEmployerCheckoutData()
  },
  mounted () {
    this.renderTooltips()
  },
  updated () {
    this.renderTooltips()
  }
}
</script>

<style lang="scss" scoped>
.label-view {
  margin-bottom: 5px;
}

#gender {
  min-width: 130px;
}

#country {
  min-width: 220px;
}

#companyGewerbeverein {
  min-width: 350px;
}

.input-view {
  border-radius: 0;
}

.form-control:focus,
.form-select:focus {
  border: 1px solid #003882;
  box-shadow: 0 0 0 0.1rem #003882;
  padding-left: 5px;
}

.form-control.time-field {
  width: auto;
  display: inline-block;
}

.form-select {
  width: 260px;
}

#app .form-control:disabled,
#app .form-control[readonly] {
  background-color: #75797c !important;
  opacity: 0.6;
}
</style>
