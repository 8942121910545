<template>
 <div class="container py-1 py-md-5">
  <div class="content pt-0">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center mb-5 mg-lg-0">
        <a href="/login">
          <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid w-50"/>
        </a>
      </div>
      <div class="col-12 col-lg-8">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <h1><span class="fw-bold">Registrierung</span> als Partner</h1>
          <p>Hier können Sie Ihren Akzeptanzpartnervertrag abschließen</p>
          <div v-if="currentStep === 1">
            <h3>Firmendaten (für Vertrag und Rechnung)</h3>
              <div class="pt-2">
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyName" class="form-label label-view">Firmenname (ggf. mit Rechtsform)</label>
                      <input v-model="registrationData.companyName" type="text" id="companyName" class="form-control input-view" placeholder="Firmennamen eingeben">
                      <div id="companyNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyAddressAdditional" class="form-label label-view">Adresse-Zusatz</label>
                      <input v-model="registrationData.companyAddressAdditional" type="text" id="companyAddressAdditional" class="form-control input-view" placeholder="Adress-Zusatz eingeben (optional)">
                      <div id="companyAddressAdditionalNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyStreet" class="form-label label-view">Straße und Hausnummer</label>
                      <input v-model="registrationData.companyStreet" type="text" id="companyStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                      <div id="companyStreetNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyZip" class="form-label label-view">Postleitzahl</label>
                      <input v-model="registrationData.companyZip" type="text" id="companyZip" class="form-control input-view" placeholder="PLZ eingeben">
                      <div id="companyZipNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyCity" class="form-label label-view">Ort</label>
                      <input v-model="registrationData.companyCity" type="text" id="companyCity" class="form-control input-view" placeholder="Ort eingeben">
                      <div id="companyCityNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyCountry" class="form-label label-view">Land</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.companyCountry" class="form-select input-view" id="companyCountry">
                            <option value="Deutschland">Deutschland</option>
                            <option value="Schweiz">Schweiz</option>
                            <option value="Österreich">Österreich</option>
                            <option value="Frankreich">Frankreich</option>
                            <option value="Belgien">Belgien</option>
                            <option value="Luxemburg">Luxemburg</option>
                            <option value="Dänemark">Dänemark</option>
                            <option value="Norwegen">Norwegen</option>
                            <option value="Schweden">Schweden</option>
                            <option value="Schweden">Schweden</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Niederlande">Niederlande</option>
                            <option value="Polen">Polen</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Rumänien">Rumänien</option>
                            <option value="Russland">Russland</option>
                            <option value="Griechenland">Griechenland</option>
                            <option value="Albanien">Albanien</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Bulgarien">Bulgarien</option>
                            <option value="Italien">Italien</option>
                            <option value="Irland">Irland</option>
                            <option value="Estland">Estland</option>
                            <option value="Lettland">Lettland</option>
                            <option value="Litauen">Litauen</option>
                            <option value="Kasachstan">Kasachstan</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Serbien">Serbien</option>
                            <option value="Slowakei">Slowakei</option>
                            <option value="Slowenien">Slowenien</option>
                            <option value="Spanien">Spanien</option>
                            <option value="Tschechien">Tschechien</option>
                            <option value="Türkei">Türkei</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="Ungarn">Ungarn</option>
                            <option value="Vereinigtes Königreich">Vereinigtes Königreich</option>
                            <option value="Malta">Malta</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Nordmazedonien">Nordmazedonien</option>
                          </select>
                        </div>
                      </div>
                      <div id="companyCountryNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top" v-if="this.$store.state.region.name !== 'Leinfelden-Echterdingen'">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyGewerbeverein" class="form-label label-view">Mitglied Gewerbeverein (Info)</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.companyGewerbeverein" class="form-select input-view" id="companyGewerbeverein" readonly="readonly" disabled="disabled">
                            <option v-for="vereinsname in regionCheckoutData.requirement_to_join_club_names" :key="vereinsname.requirement_to_join_club_name" :value="vereinsname.requirement_to_join_club_name">{{vereinsname.requirement_to_join_club_name}}</option>
                            <option value="Kein Mitglied, möchte aber Infos">Kein Mitglied, möchte aber Infos</option>
                            <option value="Kein Mitglied">Kein Mitglied</option>
                          </select>
                        </div>
                      </div>
                      <div id="companyGewerbevereinNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyEmail" class="form-label label-view">E-Mail-Adresse (Rechnung/Vertrag)</label>
                    <input v-model="registrationData.companyEmail" type="email" id="companyEmail" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="70">
                    <div id="companyEmailNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyEmailRepeated" class="form-label label-view">E-Mail-Adresse (Rechnung/Vertrag) wiederholen</label>
                    <input v-model="registrationData.companyEmailRepeated" type="email" id="companyEmailRepeated" class="form-control input-view" placeholder="E-Mail-Adresse wiederholen" maxlength="100">
                    <div id="companyEmailRepeatedNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <br />
              <h3>Inhaber / Geschäftsführer</h3>
              <span class="fst-italic">Dies sind keine Login-Daten und dienen nur zur Information.</span>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCeoName" class="form-label label-view">Vorname Nachname</label>
                    <input v-model="registrationData.ceoName" type="text" id="ceoName" class="form-control input-view" placeholder="Vorname Nachname des/r Geschäftsführer/in" maxlength="30">
                    <div id="ceoNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCeoPhone" class="form-label label-view">Telefon (Inhaber/Geschäftsführer)</label>
                    <input v-model="registrationData.ceoPhone" type="text" id="ceoPhone" class="form-control input-view" placeholder="Telefonnummer des/r Geschäftsführer/in" maxlength="30">
                    <div id="ceoPhoneNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <br />
              <h3>Ansprechpartner (Admin der Firma)</h3>
              <span class="fst-italic">Dies ist Ihr ursprünglicher Interessenten-Account.<br />Für Änderungen wenden Sie sich bitte an den Support.</span>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonGender" class="form-label label-view">Geschlecht</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select :value="registrationData.contactPersonGender" class="form-select input-view" id="contactPersonGender" disabled>
                          <option value="männlich">männlich</option>
                          <option value="weiblich">weiblich</option>
                          <option value="divers">divers</option>
                        </select>
                      </div>
                    </div>
                    <div id="contactPersonGenderNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonFirstName" class="form-label label-view">Vorname</label>
                    <input :value="registrationData.contactPersonFirstName" type="text" id="contactPersonFirstName" class="form-control input-view" placeholder="Vorname eingeben" maxlength="30" readonly>
                    <div id="contactPersonFirstNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonLastName" class="form-label label-view">Nachname</label>
                    <input :value="registrationData.contactPersonLastName" type="text" id="contactPersonLastName" class="form-control input-view" placeholder="Nachname eingeben" maxlength="30" readonly>
                    <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonLastName" class="form-label label-view">E-Mail</label>
                    <input :value="registrationData.contactPersonEmail" type="text" id="contactPersonEmail" class="form-control input-view" maxlength="30" readonly>
                    <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div v-if="this.registrationData.sktype && this.registrationData.sktype.toLowerCase().includes('kein disagio')">
                <h3>Disagio je Transaktion (Tx):</h3>
                <div class="row my-3 text-muted">
                  <div class="col-lg-12">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="registrationData.disagio" id="0% - Sondertarif" value="0% - Sondertarif" checked>
                      <label class="form-check-label" for="0% - Sondertarif">
                        <span>0% - Sondertarif</span>
                      </label>
                    </div>
                    <div id="disagioNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="registrationData.community === 'Lahr'">
                  <h3>Ich wähle zunächst folgendes Disagio-Modell</h3>
                  <span class="fst-italic">Kann jederzeit für die Zukunft geändert werden / (mind. 0,20 € je Buchung, außer bei 3,0% Disagio)</span>
                  <div class="pt-2 border-top">
                    <div class="row">
                      <div class="col-lg-12">
                        <div v-for="(disagioModel, index) in disagioModels" :key="index">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" v-model="registrationData.disagio" :id="disagioModel.id" :value="disagioModel.value">
                            <label class="form-check-label" :for="disagioModel.id">
                              <span v-html="disagioModel.description"></span>
                            </label>
                          </div>
                        </div>
                        <div id="disagioNote" class="form-text error-note"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h3>Disagio je Transaktion (Tx):</h3>
                  <div class="row my-3 text-muted">
                    <div class="col-lg-12">
                      <div v-for="(disagioModel, index) in specialDisagioModels" :key="index">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" v-model="registrationData.disagio" :id="disagioModel.id" :value="disagioModel.value" checked>
                          <label class="form-check-label" :for="disagioModel.id">
                            <span v-html="disagioModel.description"></span>
                          </label>
                        </div>
                      </div>
                      <div id="disagioNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <h6>Erläuterung zur Berechnung:</h6>
                  <table class="table table-bordered text-muted">
                    <tr><td>Einlöse-Tx (Guthabeneinlösung):</td><td>2,0% (mind. 0,20 € je Tx) des Einlösebetrages</td></tr>
                    <tr><td>Bonus-Tx (Einkaufsbonus vergeben):</td><td>2,0% (mind. 0,20 € je Tx) des Einkaufsbetrages, <strong>aber maximal 0,50 € je Buchung (Tx)</strong></td></tr>
                    <tr><td>Auflade-Tx (Gutschein-/Guthabenaufladung):</td><td><strong>kostenfrei</strong></td></tr>
                  </table>
                  <br />
                  <div class="text-danger" v-if="registrationData.community === 'Ettenheim'">
                    <strong>Bis Ende des Jahres 2023 werden die Disagio-Kosten vom Auftraggeber der {{ this.$store.state.region.cardName }} übernommen.</strong><br />
                    Über eine Fortführung der Aktion im Jahr 2024 wird Ende 2023 entschieden.<br />
                    Alle Partner erhalten rechtzeitig entsprechende Informationen, um für das Jahr 2024 planen zu können.
                  </div>
                  <div class="text-danger" v-if="registrationData.community === 'Leinfelden-Echterdingen' || registrationData.community === 'Landshut' || registrationData.community === 'Herbolzheim' || registrationData.community === 'Schwabach' || registrationData.community === 'Gräfelfing' || registrationData.community === 'Planegg'">
                    <strong>Bis Ende des Jahres 2024 werden die Disagio-Kosten vom Auftraggeber der {{ this.$store.state.region.cardName }} übernommen.</strong><br />
                    Über eine Fortführung der Aktion im Jahr 2025 wird Ende 2024 entschieden.<br />
                    Alle Partner erhalten rechtzeitig entsprechende Informationen, um für das Jahr 2025 planen zu können.
                  </div>
                </div>
              </div>
              <br />
              <br />
              <h3>Zusätzliche MitarbeiterCARD?</h3>
              <span class="fst-italic" v-if="Number(this.regionCheckoutData.partner_and_mitarbeitercard_entry_fee_discount) > 0">Möchten Sie zusätzlich einen Partnervertrag für die MitarbeiterCARD abschließen? Sie erhalten hierbei einen {{ this.regionCheckoutData.partner_and_mitarbeitercard_entry_fee_discount }}€ Rabatt, wenn Sie gleichzeitig die MitarbeiterCARD beantragen.</span>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="registrationData.isAlsoEmployer" id="isAlsoEmployer">
                      <label class="form-check-label" for="isAlsoEmployer">
                        Ja, ich möchte zusätzlich die MitarbeiterCARD beantragen
                      </label>
                      <div id="isAlsoEmployerNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="registrationData.isAlsoEmployer">
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputEmployerLoginEmail" class="form-label label-view">E-Mail-Adresse (Arbeitgeber Login)</label>
                      <input v-model="registrationData.employerLoginEmail" type="email" id="employerLoginEmail" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="70">
                      <div id="employerLoginEmailNote" class="form-text error-note"></div>
                    </div>
                    <div class="col-lg-5">
                      <img class="d-block img-fluid" :src="require('../assets/img/booking/circle-info.svg')" width="20">
                      Bitte eine andere E-Mail-Adresse als für den Akzeptanzpartner-Login verwenden.
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputEmployerLoginEmailRepeated" class="form-label label-view">E-Mail-Adresse (Arbeitgeber Login) wiederholen</label>
                      <input v-model="registrationData.employerLoginEmailRepeated" type="email" id="employerLoginEmailRepeated" class="form-control input-view" placeholder="E-Mail-Adresse wiederholen" maxlength="100">
                      <div id="employerLoginEmailRepeatedNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputEmployerPassword" class="form-label label-view">Passwort</label>
                      <input v-model="registrationData.employerPassword" @input="passwordChecker" type="password" id="employerPassword" class="form-control input-view" placeholder="Passwort eingeben" maxlength="50">
                      <div id="employerPasswordNote" class="form-text error-note"></div>
                      <div id="passwordChecker" class="form-text text-start">
                          <div id="passwordCheckerMinCharacter"><img id="passwordCheckerMinCharacterIcon" v-bind:src="getIcon(passwordCheckerMinCharacterIcon)" width="15" height="15"> enthält mind. 8 Zeichen</div>
                          <div id="passwordCheckerCapitalLetter"><img id="passwordCheckerCapitalLetterIcon" v-bind:src="getIcon(passwordCheckerCapitalLetterIcon)" width="15" height="15"> enthält mind. 1 Großbuchstaben</div>
                          <div id="passwordCheckerNumber"><img id="passwordCheckerNumberIcon" v-bind:src="getIcon(passwordCheckerNumberIcon)" width="15" height="15"> enthält mind. 1 Ziffer</div>
                          <div id="passwordCheckerSpecialCharacter"><img id="passwordCheckerSpecialCharacterIcon" v-bind:src="getIcon(passwordCheckerSpecialCharacterIcon)" width="15" height="15"> enthält mind. 1 Sonderzeichen (!$%()*,-.?@^_~)</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputEmployerPasswordRepeated" class="form-label label-view">Passwort wiederholen</label>
                      <input v-model="registrationData.employerPasswordRepeated" type="password" id="employerPasswordRepeated" class="form-control input-view" placeholder="Passwort wiederholen" maxlength="50">
                      <div id="employerPasswordRepeatedNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div class="pt-2 mb-3 border-top">
                <div class="text-end">
                  <button @click="nextStep()" class="btn btn-primary ms-4">Vertrag abschließen >></button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="currentStep === 2">
            <p>Sie haben einen Gutscheincode? <span class="voucher-link" @click="onOpenVoucherClicked()"><u>Hier Gutscheincode eingeben</u></span></p>
            <div v-if="shouldShowVoucherInput">
              <div class="row">
                <div class="col-lg-7">
                  <label for="voucherCode" class="form-label label-view">Gutscheincode</label>
                  <div class="input-group">
                    <input v-model="voucherCode" type="text" id="voucherCode" class="form-control input-view" placeholder="Gutscheincode eingeben" aria-describedby="add-voucher-button">
                    <button class="btn btn-primary" id="add-voucher-button" @click="setVoucherValue()">Gutscheincode anwenden</button>
                  </div>
                  <div id="vocherCodeNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 mb-3 border-top">
              <div class="row">
                <div class="col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Produktname</th>
                        <th v-if="voucherValue > 0 || voucherPercent > 0">Gutscheincode</th>
                        <th v-if="isMemberInTradeAssociation && regionHasTradeAssociationPartnerVoucher">Rabatt</th>
                        <th v-if="isMemberInTradeAssociation && regionHasTradeAssociationEmployerVoucher && this.registrationData.isAlsoEmployer">Rabatt</th>
                        <th>Preis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Partnervertrag {{ this.$store.state.user.cardName }}{{ this.registrationData.isAlsoEmployer? ' + Partnervertrag MitarbeiterCARD' : '' }}<br /><span class="fst-italic text-muted" v-html="regionCheckoutData.partner_entry_fee_description_text"></span></td>
                        <td v-if="voucherValue > 0"><span class="text-success fw-bold">Gutscheincode "{{voucherCode}}" mit {{voucherValue}}€ netto Rabatt aktiv</span></td>
                        <td v-if="voucherPercent > 0"><span class="text-success fw-bold">Gutscheincode "{{voucherCode}}" mit {{(voucherPercent * 100)}}% Rabatt aktiv</span></td>
                        <td v-if="isMemberInTradeAssociation && regionHasTradeAssociationPartnerVoucher">
                          <span class="text-success fw-bold">Partnervertrag {{ this.$store.state.user.cardName }} Rabatt für Mitgliedschaft im Gewerbeverein:<br />{{ regionCheckoutData.partner_entry_fee_membership_discount }}€ Rabatt aktiv</span>
                        </td>
                        <td v-if="isMemberInTradeAssociation && regionHasTradeAssociationEmployerVoucher && this.registrationData.isAlsoEmployer">
                          <span class="text-success fw-bold">Partnervertrag MitarbeiterCARD Rabatt für Mitgliedschaft im Gewerbeverein:<br />{{ regionCheckoutData.employer_entry_fee_membership_discount }}€ Rabatt aktiv</span>
                        </td>
                        <td>
                          <strong>{{ priceToPay }}€</strong><br />
                          ({{ netAmount }}€ zzgl. MwSt.)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12">
                  <h3>Sonstige Hinweise zum Vertrag</h3>
                  <div class="pt-2 border-top">
                    <div class="row">
                      <div class="col-lg-12">
                        <label for="inputCeoName" class="form-label label-view">Falls Sie sonstige Hinweise zum Vertrag haben, können Sie diese hier eingeben.</label>
                        <textarea v-model="registrationData.additionalNotesForContract" type="text" id="additionalNotesForContract" class="form-control input-view" rows="4"></textarea>
                        <div id="additionalNotesForContractNote" class="form-text error-note"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <div class="form-check text-start">
                    <input v-model="registrationData.conditionsConsent" type="checkbox" class="form-check-input p-2" id="conditionsConsent">
                    <label class="form-check-label" for="conditionsConsent">
                      Ich habe die <a :href="`${this.$store.state.region.whiteLabelWebsiteUrl}partner-bedingungen/`" target="_blank">AGB, Datenschutzhinweise und Datenschutzerklärung</a> gelesen und akzeptiere sie.*
                    </label>
                    <div id="conditionsConsentNote" class="form-text error-note"></div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <p>Im Rahmen der Registrierung und Nutzung des Partnerportals verarbeiten wir Ihre Daten. Hinweise zur Verarbeitung und Nutzung finden Sie in unseren <router-link :to="{ name: 'PrivacyPolicy' }">Datenschutzhinweisen</router-link> und der <router-link :to="{ name: 'DataProtection' }">Datenschutzerkärung</router-link>.</p>
                </div>
                <div class="col-lg-12 mt-5" v-if="this.$store.state.region.name !== 'trolleymaker'">
                  <div v-if="this.priceToPay > 0" id="paypal-button-container" ref="paypalButons"></div>
                  <button v-if="this.priceToPay <= 0" @click="saveRegistrationData()" class="btn btn-primary text-end">Partnervertrag abschließen &gt;&gt;</button>
                </div>
              </div>
            </div>
            <div class="pt-2 mb-3 border-top">
              <div class="float-start">
                <button @click="previousStep()" class="btn btn-secondary text-start">&lt;&lt; Zurück</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import UserRolesEnum from '../enums/UserRoles'
import PartnerUserRolesEnum from '../enums/PartnerUserRoles'
import { loadScript } from '@paypal/paypal-js'
require('../assets/img/circle-minus.svg')
const slugify = require('slugify')

export default {
  name: 'PartnerRegistrationPage',
  data () {
    return {
      currentStep: 1,
      registrationData: {},
      regionCheckoutData: {},
      validationStatus: false,
      paypalLoaded: false,
      showVoucherInput: false,
      voucherValue: 0,
      voucherPercent: 0,
      voucherCode: '',
      passwordCheckerMinCharacterIcon: 'icon-x.svg',
      passwordCheckerCapitalLetterIcon: 'icon-x.svg',
      passwordCheckerNumberIcon: 'icon-x.svg',
      passwordCheckerSpecialCharacterIcon: 'icon-x.svg',
      registrationDataStep1Keys: ['companyName', 'companyAddressAdditional', 'companyStreet', 'companyZip', 'companyCity', 'companyCountry', 'companyGewerbeverein', 'companyEmail', 'companyEmailRepeated', 'ceoName', 'ceoPhone', 'disagio', 'isAlsoEmployer', 'employerLoginEmail', 'employerLoginEmailRepeated', 'employerPassword', 'employerPasswordRepeated'],
      registrationDataStep2Keys: ['conditionsConsent'],
      disagioModels: [
        {
          id: 'disagio-5-percent',
          description: '5,0% - ohne monatliche Teilnahmegebühr (geeignet bei Umsätzen 500 € mtl.)',
          value: '5% - keine Teilnahmegebühr'
        },
        {
          id: 'disagio-2-percent',
          description: '2,0% - zzgl. Teilnahmegebühr (geeignet bei Umsätzen bis 1.500 € mtl.)',
          value: '2% - Standardmodell'
        },
        {
          id: 'disagio-1-percent',
          description: '1,0% - monatlicher Aufpreis 15 €, zzgl. Teilnahmegebühr (geeignet bei Umsätzen bis 3.000 € mtl.)',
          value: '1% - zzgl. 15.00 € monatlich'
        },
        {
          id: 'disagio-05-percent',
          description: '0,5% - monatlicher Aufpreis 30 €, zzgl. Teilnahmegebühr (geeignet bei Umsätzen ab 3.000 € mtl.)',
          value: '0.5% - zzgl. 30.00 € monatlich'
        },
        {
          id: 'disagio-3-percent',
          description: '3,0% - Keine Mindestgebühr je Buchung, zzgl. Teilnahmegebühr (geeignet bei Durchschnittsbon &lt; 6,66 €)',
          value: '3% - keine Mindest-Tx-Gebühr'
        }
      ],
      specialDisagioModels: [
        {
          id: 'special-disagio-2-percent',
          description: '2% - keine Teilnahmegebühr',
          value: '2% - keine Teilnahmegebühr'
        }
      ]
    }
  },
  computed: {
    priceToPay () {
      if (this.registrationData.sktype && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr gutscheincard') && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr mitarbeitercard')) {
        return 0
      } else if ((this.registrationData.sktype && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr gutscheincard') && !this.registrationData.isAlsoEmployer) || (this.registrationData.tmspartner && this.registrationData.tmspartner === true)) {
        return 0
      } else {
        return (this.netAmount * 1.19).toFixed(2)
      }
    },
    netAmount() {
      if (this.registrationData.sktype && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr gutscheincard') && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr mitarbeitercard')) {
        return 0
      }
      if ((this.registrationData.sktype && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr gutscheincard') && !this.registrationData.isAlsoEmployer) || (this.registrationData.tmspartner && this.registrationData.tmspartner === true)) {
        return 0
      }
      if (this.voucherPercent === 1) {
        return 0
      }

      let price = Number(this.regionCheckoutData.partner_entry_fee)

      if (this.registrationData.isAlsoEmployer) {
        price = price + Number(this.regionCheckoutData.mitarbeitercard_entry_fee) - Number(this.regionCheckoutData.partner_and_mitarbeitercard_entry_fee_discount)

        if (this.isMemberInTradeAssociation && this.regionHasTradeAssociationEmployerVoucher) {
          price = price - Number(this.regionCheckoutData.employer_entry_fee_membership_discount)
        }
      }

      if (this.isMemberInTradeAssociation && this.regionHasTradeAssociationPartnerVoucher) {
        price = price - Number(this.regionCheckoutData.partner_entry_fee_membership_discount)
      }

      if (this.voucherValue > 0) {
        price = price - Number(this.voucherValue)
      } else if (this.voucherPercent > 0) {
        price = price * Number(this.voucherPercent)
      }

      price = price.toFixed(2)
      return price
    },
    isMemberInTradeAssociation() {
      return typeof this.registrationData.companyGewerbeverein !== 'undefined' && this.registrationData.companyGewerbeverein !== null && this.registrationData.companyGewerbeverein.toLowerCase() !== 'kein mitglied' && this.registrationData.companyGewerbeverein.toLowerCase() !== 'kein mitglied, aber möchte infos' && this.registrationData.companyGewerbeverein.toLowerCase() !== 'kein mitglied, möchte aber infos' && this.registrationData.companyGewerbeverein.length > 0
    },
    regionHasTradeAssociationPartnerVoucher() {
      return this.regionCheckoutData.partner_entry_fee_membership_discount !== null && typeof this.regionCheckoutData.partner_entry_fee_membership_discount !== 'undefined' && this.regionCheckoutData.partner_entry_fee_membership_discount > 0
    },
    regionHasTradeAssociationEmployerVoucher() {
      return this.regionCheckoutData.employer_entry_fee_membership_discount !== null && typeof this.regionCheckoutData.employer_entry_fee_membership_discount !== 'undefined' && this.regionCheckoutData.employer_entry_fee_membership_discount > 0
    },
    cardName () {
      return this.$store.state.region.cardName ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    },
    shouldShowVoucherInput () {
      return this.showVoucherInput
    }
  },
  methods: {
    async saveRegistrationData () {
      if (this.validateRegistrationData()) {
        this.$store.commit('SET_LOADING_STATUS', true)
        this.registrationData.priceToPay = this.netAmount
        this.$store.dispatch('partnerRegistration', this.registrationData)
          .then(() => {
            window.scrollTo(0, 0)
            this.$store.commit('SET_LOADING_STATUS', false)
            this.$store.commit('SET_USER_ROLE', UserRolesEnum.PARTNER)
            this.$store.commit('SET_PARTNER_ROLE', PartnerUserRolesEnum.ADMIN)
            this.$store.commit('ADD_ALERT', { category: 'Info', message: 'Sie haben sich erfolgreich als Partner registriert. Bitte füllen Sie Ihre weiteren persönlichen Daten vollständig aus.' })
            this.$router.push({ name: 'PartnerPersonalData' })
          }).catch(() => {
            this.$store.commit('SET_LOADING_STATUS', false)
          })
      }
    },
    validateRegistrationData () {
      this.validationStatus = true
      if (this.currentStep === 1) {
        this.registrationDataStep1Keys.forEach(key => {
          this.removeValidationError(key)
          if (this.registrationData.community === 'Lahr' && key === 'disagio') {
            if (!this.registrationData.disagio) {
              document.getElementById(key + 'Note').innerHTML = 'Sie müssen ein Disagio-Modell auswählen.'
              this.validationStatus = false
              return
            }
          }
          if (this.$store.state.region.name === 'Leinfelden-Echterdingen' && key === 'companyGewerbeverein') {
            return
          }
          if (key !== 'companyAddressAdditional' && key !== 'isAlsoEmployer' && key !== 'disagio' && !((key === 'employerLoginEmail' && !this.registrationData.isAlsoEmployer) || (key === 'employerLoginEmailRepeated' && !this.registrationData.isAlsoEmployer) || (key === 'employerPassword' && !this.registrationData.isAlsoEmployer) || (key === 'employerPasswordRepeated' && !this.registrationData.isAlsoEmployer)) && !this.registrationData[key]) {
            this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
            return
          }
          if ((key === 'companyName' || key === 'companyStreet' || key === 'companyCity' || key === 'ceoPhone') && this.registrationData[key].length < 3) {
            this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
          }
          if (key === 'companyCountry' && this.registrationData[key].length < 2) {
            this.showValidationError(key, 'Ungültiger Ländercode. Bitte wenden Sie sich an den Support.')
          }
          if ((key === 'companyCity' || key === 'companyCountry' || key === 'ceoName') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
          }
          if (key === 'companyStreet' && !(/\d/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
          }
          if (key === 'ceoPhone' && !(/^[+]?[0-9 ]+$/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Die Telefonnummer ist ungültig. Sie darf nur Ziffern oder am Anfang das "+" für die Ländervorwahl enthalten.')
          }
          if (key === 'companyZip' && !(/^\d+$/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Dieses Feld darf nur Ziffern enthalten.')
          }
          if ((key === 'companyZip') && !(this.registrationData[key].length === 5 || this.registrationData[key].length === 4)) {
            this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
          }
          if ((key === 'companyEmail' || key === 'companyEmailRepeated') && this.registrationData.companyEmail !== this.registrationData.companyEmailRepeated) {
            this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
          }
          if (key === 'companyEmail' && !this.registrationData.companyEmail.includes('@')) {
            this.showValidationError(key, 'Dies ist keine gültige E-Mail-Adresse.')
          }
          if (key === 'employerLoginEmail' && this.registrationData.isAlsoEmployer && !this.registrationData.employerLoginEmail.includes('@')) {
            this.showValidationError(key, 'Dies ist keine gültige E-Mail-Adresse.')
          }
          if ((key === 'employerLoginEmail' || key === 'employerLoginEmailRepeated') && this.registrationData.isAlsoEmployer && this.registrationData.employerLoginEmail !== this.registrationData.employerLoginEmailRepeated) {
            this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
          }
          if ((key === 'employerLoginEmail' || key === 'employerLoginEmailRepeated') && this.registrationData.isAlsoEmployer && this.registrationData.employerLoginEmail === this.registrationData.contactPersonEmail) {
            this.showValidationError(key, 'Die E-Mail-Adresse für den Arbeitgeber-Login darf nicht die gleiche E-Mail-Adresse wie für den Partner Login (Ansprechpartner E-Mail-Adresse) sein.')
          }
          if (key === 'employerLoginEmail' && this.registrationData.isAlsoEmployer) {
            this.checkIfEmailAlreadyExists(this.registrationData.employerLoginEmail).then(emailAlreadyExists => {
              if (emailAlreadyExists === true) {
                this.showValidationError(key, 'Die Arbeitgeber Login E-Mail-Adresse existiert bereits. Bitte wählen Sie eine andere E-Mail-Adresse.')
              }
            }).catch(() => {
            })
          }
        })
      } else if (this.currentStep === 2) {
        this.registrationDataStep2Keys.forEach(key => {
          this.removeValidationError(key)
          if (key !== 'conditionsConsent') {
            this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
            return
          }
          if (key === 'conditionsConsent' && (!this.registrationData[key] || this.registrationData[key] === false)) {
            this.showValidationError(key, 'Sie müssen die AGB, Datenschutzhinweise und Datenschutzerklärung akzeptieren.')
          }
        })
      }
      return this.validationStatus
    },
    showValidationError (key, validationMessage) {
      document.getElementById(key).classList.add('form-element-danger')
      document.getElementById(key + 'Note').innerHTML = validationMessage
      this.validationStatus = false
    },
    removeValidationError(key) {
      const element = document.getElementById(key)
      const elementNote = document.getElementById(key + 'Note')
      if (element) {
        element.classList.remove('form-element-danger')
      }
      if (elementNote) {
        elementNote.innerHTML = ''
      }
    },
    setVoucherValue () {
      if (typeof this.voucherCode !== 'undefined' && this.voucherCode !== '') {
        if (this.voucherCode.toLowerCase() === 'trolleymaker100') {
          this.voucherValue = 0
          this.voucherPercent = 1
          this.$store.commit('ADD_ALERT', { category: 'Info', message: 'Der Gutscheincode "' + this.voucherCode + '" wurde erfolgreich angewendet und enthält einen Rabatt von ' + (this.voucherPercent * 100) + '% netto.' })
        } else if (this.voucherCode.toLowerCase() === 'dummy') {
          this.voucherValue = 10
          this.voucherPercent = 0
          this.$store.commit('ADD_ALERT', { category: 'Info', message: 'Der Gutscheincode "' + this.voucherCode + '" wurde erfolgreich angewendet und enthält einen Rabatt von ' + this.voucherValue + '€ netto.' })
        } else {
          this.voucherValue = 0
          this.voucherPercent = 0
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ungültiger Gutscheincode.' })
        }
      } else {
        this.voucherValue = 0
        this.voucherPercent = 0
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ungültiger Gutscheincode.' })
      }
    },
    onOpenVoucherClicked () {
      this.showVoucherInput = true
    },
    getIcon (imageFile) {
      return require('../assets/img/' + imageFile)
    },
    fetchCompleteInterestPersonalData () {
      return this.$store.dispatch('getCompleteInterestPersonalData')
    },
    fetchRegionPartnerCheckoutData () {
      return this.$store.dispatch('getRegionPartnerCheckoutData', { regionName: this.$store.state.region.name, regionSlug: this.$store.state.region.slug })
    },
    async checkIfEmailAlreadyExists (emailToCheck) {
      return await this.$store.dispatch('checkEmailExists', { inputEmail: emailToCheck }).then(response => {
        return response.exists
      }).catch(() => {
      })
    },
    deactivateForm () {
      const inputs = document.querySelectorAll('.form-control, .form-select, .form-check-input, .btn')
      inputs.forEach(inputElement => {
        inputElement.readOnly = true
        inputElement.disabled = true
      })
    },
    nextStep () {
      if (this.validateRegistrationData()) {
        this.currentStep = this.currentStep + 1
        window.scrollTo(0, 0)
      }
    },
    previousStep () {
      this.paypalLoaded = false
      this.currentStep = this.currentStep - 1
      window.scrollTo(0, 0)
    },
    passwordChecker () {
      if (/[A-ZÄÖÜ]+/.test(this.registrationData.employerPassword)) {
        this.passwordCheckerCapitalLetterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerCapitalLetterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.remove('passwordChecker-passed')
      }
      if (/[0-9]+/.test(this.registrationData.employerPassword)) {
        this.passwordCheckerNumberIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerNumber').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerNumberIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerNumber').classList.remove('passwordChecker-passed')
      }
      if (/[!$%()*,-.?@^_~]+/.test(this.registrationData.employerPassword)) {
        this.passwordCheckerSpecialCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerSpecialCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.remove('passwordChecker-passed')
      }
      if (this.registrationData.employerPassword.length >= 8) {
        this.passwordCheckerMinCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerMinCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.remove('passwordChecker-passed')
      }
    },
    showPayPal() {
      if (this.priceToPay > 0) {
        loadScript({
          'client-id': 'AfFBbQ79tZTtiS9yUjS3HgL8diVR4ZjazvJ6_UoDynx5u0m9Z09YZ5MgdSpKIkeMtcyM5jvjK6CXLlJA',
          currency: 'EUR'
        })
          .then((paypal) => {
            this.paypalLoaded = true
            const that = this
            paypal
              .Buttons({
                createOrder: function (data, actions) {
                  if (that.validateRegistrationData()) {
                    that.$store.commit('DELETE_ALERTS')
                    return actions.order.create({
                      purchase_units: [{
                        amount: {
                          value: that.priceToPay,
                          currency_code: 'EUR'
                        }
                      }]
                    })
                  } else {
                    that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
                  }
                },
                onApprove: function (data, actions) {
                  return actions.order.capture().then(function (details) {
                    that.saveRegistrationData()
                  })
                },
                onError: function () {
                  that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Bei der Bezahlung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.' })
                },
                onCancel: function () {
                  that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen die Bezahlung abschließen, um Ihre Registrierung abzuschließen.' })
                }
              })
              .render('#paypal-button-container')
              .catch((error) => {
                console.error('failed to render the PayPal Buttons', error)
              })
          })
          .catch((error) => {
            console.error('failed to load the PayPal JS SDK script', error)
          })
      }
    }
  },
  created() {
    this.$store.commit('SET_LOADING_STATUS', true)
    Promise.all([this.fetchCompleteInterestPersonalData(), this.fetchRegionPartnerCheckoutData()])
      .then((response) => {
        this.registrationData = response[0]
        this.regionCheckoutData = response[1][0].acf
        if (Object.prototype.hasOwnProperty.call(this.registrationData, 'sktype') && this.registrationData.sktype.toLowerCase().includes('abgeänderte einrichtungsgebühr gutscheincard')) {
          if (!Object.prototype.hasOwnProperty.call(this.registrationData, 'feePartner') || typeof this.registrationData.feePartner === 'undefined' || this.registrationData.feePartner === '') {
            this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ihre Einrichtungsgebühr konnte nicht ermittelt werden. Bitte kontaktieren Sie den Support.' })
            this.deactivateForm()
          } else {
            this.regionCheckoutData.partner_entry_fee = this.registrationData.feePartner.replace(' €', '').replace('€', '')
          }
        }
        if (Object.prototype.hasOwnProperty.call(this.registrationData, 'sktype') && this.registrationData.sktype.toLowerCase().includes('abgeänderte einrichtungsgebühr mitarbeitercard')) {
          if (!Object.prototype.hasOwnProperty.call(this.registrationData, 'feeEmployer') || typeof this.registrationData.feeEmployer === 'undefined' || this.registrationData.feeEmployer === '') {
            this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ihre Einrichtungsgebühr konnte nicht ermittelt werden. Bitte kontaktieren Sie den Support.' })
            this.deactivateForm()
          } else {
            this.regionCheckoutData.mitarbeitercard_entry_fee = this.registrationData.feeEmployer.replace(' €', '').replace('€', '')
          }
        }
        this.$store.commit('SET_LOADING_STATUS', false)
      })
      .catch(() => {
        this.deactivateForm()
        this.$store.commit('SET_LOADING_STATUS', false)
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu. Sollte das Problem bestehen bleiben, wenden Sie sich bitte an den Support.' })
      })
  },
  updated () {
    if (this.currentStep === 2 && !this.paypalLoaded) {
      this.showPayPal()
    }
  }
}
</script>

<style lang="scss" scoped>
  .label-view {
    margin-bottom: 5px;
  }

  #gender {
    min-width: 130px;
  }

  #country {
    min-width: 220px;
  }

  #companyGewerbeverein {
    min-width: 350px;
  }

  .input-view {
    border-radius: 0;
  }

  .form-control:focus, .form-select:focus {
    border: 1px solid #003882;
    box-shadow: 0 0 0 0.1rem #003882;
    padding-left: 5px;
  }

  .form-control.time-field {
    width: auto;
    display: inline-block;
  }

  .form-select {
    width: 300px;
  }

  #app .form-control:disabled,
  #app .form-control[readonly] {
    background-color: #75797c !important;
    opacity: 0.6;
  }

  .voucher-link {
    cursor: pointer;
  }
</style>
