import { createStore } from 'vuex'
import { HTTP, parseError } from '../http-common'
import router from '../router'
import UserRoles from './../enums/UserRoles'
import PartnerUserRoles from './../enums/PartnerUserRoles'

export default createStore({
  state: {
    loginStatus: false,
    loadingStatus: false,
    user: {},
    alerts: [],
    region: {}
  },
  mutations: {
    SET_LOGIN_STATUS (state, loginStatus) {
      state.loginStatus = loginStatus
    },
    SET_LOADING_STATUS (state, loadingStatus) {
      state.loadingStatus = loadingStatus
    },
    SET_USER (state, user) {
      state.user = user
      if (Object.keys(user).length !== 0 && user !== undefined && user !== null && user.email && user.email !== '') {
        /* const tempRegion = {}
        tempRegion.name = user.region
        tempRegion.cardName = Helper.getCardNameForRegion(user.region)
        tempRegion.primaryColor = Helper.getPrimaryColorForRegion(user.region)
        state.region = tempRegion */
        // const tempRegion = Helper.getRegionObjectForSlug(user.region)
        // state.region = tempRegion
        // this.commit('SET_REGION', state.region)
      }
    },
    SET_REGION (state, region) {
      state.region = region
      localStorage.setItem('myCityCardsRegion', JSON.stringify(region))
    },
    SET_USER_DATA (state, payload) {
      state.user[payload.key] = payload.value
    },
    SET_USER_ROLE (state, role) {
      state.user.role = role
      const localStorageUser = JSON.parse(localStorage.getItem('myCityCardsUser'))
      localStorageUser.role = role
      localStorage.setItem('myCityCardsUser', JSON.stringify(localStorageUser))
    },
    SET_PARTNER_ROLE (state, role) {
      state.user.partner_role = role
      const localStorageUser = JSON.parse(localStorage.getItem('myCityCardsUser'))
      localStorageUser.partner_role = role
      localStorage.setItem('myCityCardsUser', JSON.stringify(localStorageUser))
    },
    ADD_ALERT (state, alert) {
      state.alerts.push(alert)
    },
    DELETE_ALERT (state, index) {
      state.alerts.splice(index, 1)
    },
    DELETE_ALERTS (state) {
      state.alerts.length = 0
    }
  },
  actions: {
    initialiseApp ({ commit }) {
      if (localStorage.getItem('myCityCardsLoginStatus')) {
        commit('SET_LOGIN_STATUS', localStorage.getItem('myCityCardsLoginStatus'))
        commit('SET_USER', JSON.parse(localStorage.getItem('myCityCardsUser')))
      }
      if (localStorage.getItem('myCityCardsRegion')) {
        commit('SET_REGION', JSON.parse(localStorage.getItem('myCityCardsRegion')))
      }
    },
    addAlert ({ commit }, alertData) {
      return new Promise((resolve) => {
        commit('ADD_ALERT', { category: alertData.category, message: alertData.message })
        resolve()
      })
    },
    customerLogin ({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        HTTP.post('customer-login', loginData)
          .then(response => {
            if (response.status !== 200) {
              const errorMessage = 'Login fehlgeschlagen'
              reject(new Error(errorMessage))
            }
            commit('SET_LOGIN_STATUS', true)
            commit('SET_USER', response.data)
            this.dispatch('getRegionData', response.data.region).then(regionResponse => {
              localStorage.setItem('myCityCardsLoginStatus', true)
              localStorage.setItem('myCityCardsUser', JSON.stringify(response.data))
              router.push('/')
              resolve()
            }, error => {
              const errorMessage = parseError(error, 'Es ist ein Fehler bei der Regionszuordnung aufgetreten. Bitte kontaktieren Sie den Support.')
              commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
              reject(new Error(error))
            })
          }).catch((error) => {
            const errorMessage = parseError(error, 'Login fehlgeschlagen')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Login ist ein Fehler aufgetreten.'))
          })
      })
    },
    partnerLogin ({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        HTTP.post('partner-login', loginData)
          .then(response => {
            if (response.status !== 200) {
              const errorMessage = 'Login fehlgeschlagen'
              reject(new Error(errorMessage))
            }
            commit('SET_LOGIN_STATUS', true)
            commit('SET_USER', response.data)
            this.dispatch('getRegionData', response.data.region).then(regionResponse => {
              localStorage.setItem('myCityCardsLoginStatus', true)
              localStorage.setItem('myCityCardsUser', JSON.stringify(response.data))
              if (response.data.role === UserRoles.INTERESTED) {
                router.push({ name: 'PartnerInfo' })
              } else {
                if (response.data.partner_role === PartnerUserRoles.ADMIN && response.data.partner_data_complete !== true) {
                  router.push({ name: 'PartnerPersonalData' })
                } else {
                  router.push('/')
                }
              }
              resolve()
            }, error => {
              const errorMessage = parseError(error, 'Es ist ein Fehler bei der Regionszuordnung aufgetreten. Bitte kontaktieren Sie den Support.')
              commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
              reject(new Error(error))
            })
          }, error => {
            const errorMessage = parseError(error, 'Login fehlgeschlagen')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Login ist ein Fehler aufgetreten.'))
          })
      })
    },
    contractorLogin ({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        HTTP.post('contractor-login', loginData)
          .then(response => {
            if (response.status !== 200) {
              const errorMessage = 'Login fehlgeschlagen'
              reject(new Error(errorMessage))
            }
            commit('SET_LOGIN_STATUS', true)
            commit('SET_USER', response.data)
            this.dispatch('getRegionData', response.data.region).then(regionResponse => {
              localStorage.setItem('myCityCardsLoginStatus', true)
              localStorage.setItem('myCityCardsUser', JSON.stringify(response.data))
              router.push({ name: 'ContractorStatistics' })
              resolve()
            }, error => {
              const errorMessage = parseError(error, 'Es ist ein Fehler bei der Regionszuordnung aufgetreten. Bitte kontaktieren Sie den Support.')
              commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
              reject(new Error(error))
            })
          }, error => {
            const errorMessage = parseError(error, 'Login fehlgeschlagen')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Login ist ein Fehler aufgetreten.'))
          })
      })
    },
    trolleymakerportalLogin ({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        HTTP.post('trolleymakerportal-login', loginData)
          .then(response => {
            if (response.status !== 200) {
              const errorMessage = 'Login fehlgeschlagen'
              reject(new Error(errorMessage))
            }
            commit('SET_LOGIN_STATUS', true)
            commit('SET_USER', response.data)
            this.dispatch('getRegionData', response.data.region).then(regionResponse => {
              localStorage.setItem('myCityCardsLoginStatus', true)
              localStorage.setItem('myCityCardsUser', JSON.stringify(response.data))
              router.push({ name: 'PushNotifications' })
              resolve()
            }, error => {
              const errorMessage = parseError(error, 'Es ist ein Fehler bei der Regionszuordnung aufgetreten. Bitte kontaktieren Sie den Support.')
              commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
              reject(new Error(error))
            })
          }, error => {
            const errorMessage = parseError(error, 'Login fehlgeschlagen')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Login ist ein Fehler aufgetreten.'))
          })
      })
    },
    employerLogin ({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        HTTP.post('employer-login', loginData)
          .then(response => {
            if (response.status !== 200) {
              const errorMessage = 'Login fehlgeschlagen'
              reject(new Error(errorMessage))
            }
            commit('SET_LOGIN_STATUS', true)
            commit('SET_USER', response.data)
            this.dispatch('getRegionData', response.data.region).then(regionResponse => {
              localStorage.setItem('myCityCardsLoginStatus', true)
              localStorage.setItem('myCityCardsUser', JSON.stringify(response.data))
              if (response.data.role === UserRoles.INTERESTED) {
                router.push({ name: 'PartnerInfo' })
              } else {
                if (response.data.partner_role === PartnerUserRoles.ADMIN && response.data.partner_data_complete !== true) {
                  router.push({ name: 'EmployerPersonalData' })
                } else {
                  router.push('/')
                }
              }
              resolve()
            }, error => {
              const errorMessage = parseError(error, 'Es ist ein Fehler bei der Regionszuordnung aufgetreten. Bitte kontaktieren Sie den Support.')
              commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
              reject(new Error(error))
            })
          }, error => {
            const errorMessage = parseError(error, 'Login fehlgeschlagen')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Login ist ein Fehler aufgetreten.'))
          })
      })
    },
    getRegionData ({ commit }, regionName) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/?_fields=acf,id&region_slug=' + regionName + '&region_name=' + regionName, {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            if (response.data.length !== 1) {
              const errorMessage = parseError(undefined, 'Es ist ein Fehler bei der Regionszuordnung aufgetreten. Bitte kontaktieren Sie den Support.')
              reject(new Error(errorMessage))
              return
            }
            const regionFromServer = response.data[0].acf
            const tempRegion = {}
            tempRegion.name = regionFromServer.region_name
            tempRegion.primaryColor = regionFromServer.primary_color
            tempRegion.cardName = regionFromServer.card_name
            tempRegion.whiteLabelWebsiteUrl = regionFromServer.white_label_website_url
            tempRegion.whiteLabelWebsiteDomain = regionFromServer.white_label_website_domain
            tempRegion.slug = regionFromServer.region_slug
            tempRegion.id = response.data[0].id
            this.commit('SET_REGION', tempRegion)
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getAllRegions ({ commit }, regionName) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/?_fields=acf,slug', {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            resolve(response)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getRegionPartnerCheckoutData ({ commit }, region) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/?_fields=acf.partner_entry_fee_membership_discount,acf.employer_entry_fee_membership_discount,acf.partner_entry_fee,acf.partner_entry_fee_description_text,acf.requirement_to_join_club_names,acf.mitarbeitercard_entry_fee,acf.partner_and_mitarbeitercard_entry_fee_discount&region_slug=' + region.regionSlug + '&region_name=' + region.regionName, {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            if (response.data.length !== 1) {
              const errorMessage = parseError(undefined, 'Beim Abrufen der Daten ist ein Fehler aufgetreten. Die Registrierung ist aktuell nicht möglich. Bitte kontaktieren Sie den Support.')
              commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
              reject(new Error(errorMessage))
              return
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Beim Abrufen der Daten ist ein Fehler aufgetreten. Die Registrierung ist aktuell nicht möglich. Bitte kontaktieren Sie den Support.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    getRegionEmployerCheckoutData ({ commit }, region) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/?_fields=acf.requirement_to_join_club_names&region_slug=' + region.regionSlug + '&region_name=' + region.regionName, {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            if (response.data.length !== 1) {
              const errorMessage = parseError(undefined, 'Beim Abrufen der Daten ist ein Fehler aufgetreten. Die Registrierung ist aktuell nicht möglich. Bitte kontaktieren Sie den Support.')
              commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
              reject(new Error(errorMessage))
              return
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Beim Abrufen der Daten ist ein Fehler aufgetreten. Die Registrierung ist aktuell nicht möglich. Bitte kontaktieren Sie den Support.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    customerRegistration ({ commit }, registrationData) {
      return new Promise((resolve, reject) => {
        HTTP.post('customer-registration', registrationData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Sie wurden erfolgreich registriert und können sich nun einloggen.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Registrieren ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Registrieren ist ein Fehler aufgetreten.'))
          })
      })
    },
    interestRegistration ({ commit }, registrationData) {
      return new Promise((resolve, reject) => {
        HTTP.post('interest-registration', registrationData)
          .then(response => {
            if (typeof registrationData.prefilledPartner !== 'undefined') {
              commit('ADD_ALERT', { category: 'Info', message: 'Sie wurden erfolgreich registriert und automatisch eingeloggt.' })
            } else {
              commit('ADD_ALERT', { category: 'Info', message: 'Sie wurden erfolgreich registriert. Sie werden per E-Mail benachrichtigt, sobald Ihr Account freigeschaltet wurde.' })
            }
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Registrieren ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    partnerRegistration ({ commit }, registrationData) {
      return new Promise((resolve, reject) => {
        HTTP.post('partner-registration', registrationData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Sie wurden erfolgreich als Partner registriert. Bitte nehmen Sie sich einen Moment Zeit und vervollständigen Sie die restlichen Angaben zur Veröffentlichung und Technik am POS.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Registrieren ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    employerRegistration ({ commit }, registrationData) {
      return new Promise((resolve, reject) => {
        HTTP.post('employer-registration', registrationData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Sie wurden erfolgreich als Arbeitgeber registriert. Bitte nehmen Sie sich einen Moment Zeit und vervollständigen Sie die restlichen Angaben.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Registrieren ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    resetPassword ({ commit }, resetPasswordData) {
      return new Promise((resolve, reject) => {
        HTTP.post('reset-password', resetPasswordData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Falls Ihr Account gefunden wurde, sollten Sie in diesem Moment eine E-Mail erhalten haben, mit einem Bestätigungslink, um Ihr Passwort zurückzusetzen. Bitte schauen Sie auch in Ihrem Spam-Ordner nach.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Passwort zurücksetzen ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    newPassword ({ commit }, resetPasswordData) {
      return new Promise((resolve, reject) => {
        HTTP.post('new-password', resetPasswordData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Ihr Passwort wurde erfolgreich geändert. Sie können sich nun mit dem neuen Passwort einloggen.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Passwort speichern ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.post('logout')
          .then(() => {
            commit('SET_LOGIN_STATUS', false)
            commit('SET_USER', {})
            localStorage.removeItem('myCityCardsLoginStatus')
            localStorage.removeItem('myCityCardsUser')
            resolve()
          }, (error) => {
            commit('SET_LOGIN_STATUS', false)
            commit('SET_USER', {})
            localStorage.removeItem('myCityCardsLoginStatus')
            localStorage.removeItem('myCityCardsUser')
            reject(error)
          })
      })
    },
    logoutWithPromise ({ commit }) {
      return new Promise((resolve) => {
        commit('SET_LOGIN_STATUS', false)
        commit('SET_USER', {})
        localStorage.removeItem('myCityCardsLoginStatus')
        localStorage.removeItem('myCityCardsUser')
        resolve()
      })
    },
    getDashboard ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('dashboard?amountOfTransactions=5')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    getCardIDs ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('cardids')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    getPersonalData ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('personal-data')
          .then(response => {
            if (response) {
              commit('SET_USER_DATA', { key: 'email', value: response.data.email })
              commit('SET_USER_DATA', { key: 'gender', value: response.data.gender })
              commit('SET_USER_DATA', { key: 'salutation', value: response.data.salutation })
              commit('SET_USER_DATA', { key: 'firstName', value: response.data.firstName })
              commit('SET_USER_DATA', { key: 'lastName', value: response.data.lastName })

              const localStorageUser = JSON.parse(localStorage.getItem('myCityCardsUser'))
              localStorageUser.email = response.data.email
              localStorageUser.gender = response.data.gender
              localStorageUser.salutation = response.data.salutation
              localStorageUser.firstName = response.data.firstName
              localStorageUser.lastName = response.data.lastName

              localStorage.setItem('myCityCardsUser', JSON.stringify(localStorageUser))
              resolve(response.data)
            } else {
              const errorMessage = parseError(undefined, 'Persönliche Daten konnten nicht abgerufen werden.')
              commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            }
          }, error => {
            const errorMessage = parseError(error, 'Persönliche Daten konnten nicht abgerufen werden.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    updatePartnerPersonalData ({ commit }, partnerPersonalData) {
      return new Promise((resolve, reject) => {
        HTTP.post('partner-personal-data', partnerPersonalData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Die Daten wurden erfolgreich aktualisiert.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Es ist ein Fehler aufgetreten.'))
          })
      })
    },
    updateEmployerPersonalData ({ commit }, partnerPersonalData) {
      return new Promise((resolve, reject) => {
        HTTP.post('employer-personal-data', partnerPersonalData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Die Daten wurden erfolgreich aktualisiert.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Es ist ein Fehler aufgetreten.'))
          })
      })
    },
    getTransactions ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('transactions')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    getPartnerTransactions ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('partner-transactions')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    getBalance ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('check-balance')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    updatePassword ({ commit }, updatePasswordData) {
      return new Promise((resolve, reject) => {
        HTTP.post('change-password', updatePasswordData)
          .then(response => {
            const user = JSON.parse(localStorage.getItem('myCityCardsUser'))
            user.token = response.data.token
            localStorage.setItem('myCityCardsUser', JSON.stringify(user))
            commit('ADD_ALERT', { category: 'Info', message: 'Ihr Passwort wurde erfolgreich geändert.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Passwort konnte nicht geändert werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Passwort ändern ist ein Fehler aufgetreten.'))
          })
      })
    },
    addNewCard({ commit }, newCardData) {
      return new Promise((resolve, reject) => {
        HTTP.post('add-card', newCardData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Das Formular wurde erfolgreich übermittelt.' })
            resolve()
          })
          .catch(error => {
            const errorMessage = parseError(error, 'Beim Versenden des Formulares ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Versenden des Formulares ist ein Fehler aufgetreten.'))
          })
      })
    },
    updateLockCard ({ commit }, updateLockCardData) {
      return new Promise((resolve, reject) => {
        HTTP.post('lock-card', updateLockCardData)
          .then(response => {
            commit('SET_LOGIN_STATUS', false)
            commit('SET_USER', {})
            localStorage.removeItem('myCityCardsLoginStatus')
            localStorage.removeItem('myCityCardsUser')
            location.reload()
          }, error => {
            const errorMessage = parseError(error, 'Die CARD konnte nicht gesperrt werden.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    contactForm ({ commit }, contactFormData) {
      return new Promise((resolve, reject) => {
        HTTP.post('contact-form', contactFormData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Die Nachricht wurde erfolgreich verschickt' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Versenden des Kontaktformulares ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Versenden des Kontaktformulares ist ein Fehler aufgetreten.'))
          })
      })
    },
    interestContactForm ({ commit }, contactFormData) {
      return new Promise((resolve, reject) => {
        HTTP.post('interest-contact-form', contactFormData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Die Nachricht wurde erfolgreich verschickt' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Versenden des Kontaktformulares ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Versenden des Kontaktformulares ist ein Fehler aufgetreten.'))
          })
      })
    },
    uploadLogo ({ commit }, logoData) {
      return new Promise((resolve, reject) => {
        HTTP.post('set-logo', logoData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Ihr Logo wurde erfolgreich aktualisiert.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Hochladen Ihres Logos ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Hochladen Ihres Logos ist ein Fehler aufgetreten.'))
          })
      })
    },
    getLogo ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.post('get-logo')
          .then(response => {
            resolve(response)
          }, error => {
            if (error.response.status === 404) {
              resolve(error.response)
            } else {
              const errorMessage = parseError(error, 'Fehler beim Abrufen des Logos.')
              commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
              reject(new Error('Fehler beim Abrufen des Logos.'))
            }
          })
      })
    },
    uploadEmployerCardsList ({ commit }, employerCardsListData) {
      return new Promise((resolve, reject) => {
        HTTP.post('add-employercards-list', employerCardsListData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Die Ladeliste wurde erfolgreich hochgeladen.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Hochladen Ihrer Ladeliste ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Hochladen Ihres Logos ist ein Fehler aufgetreten.'))
          })
      })
    },
    transferBalance ({ commit }, transferBalanceData) {
      return new Promise((resolve, reject) => {
        HTTP.post('transfer-balance', transferBalanceData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Der Guthabenübertrag wurde erfolgreich eingereicht.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Versenden des Guthabenübertrags ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Versenden des Guthabenübertrags ist ein Fehler aufgetreten.'))
          })
      })
    },
    saveConsents ({ commit }, updateConsentsData) {
      return new Promise((resolve, reject) => {
        HTTP.post('update-consents', updateConsentsData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Die Änderung wurde erfolgreich gespeichert.' })
            resolve(response)
          }, error => {
            const errorMessage = parseError(error, 'Beim Speichern der Einwilligungserklärungen ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    savePersonalData ({ commit }, updatePersonalData) {
      return new Promise((resolve, reject) => {
        HTTP.post('customer-update-personal-data', updatePersonalData)
          .then(response => {
            commit('SET_USER_DATA', { key: 'email', value: response.data.email })
            commit('SET_USER_DATA', { key: 'gender', value: response.data.gender })
            commit('SET_USER_DATA', { key: 'salutation', value: response.data.salutation })
            commit('SET_USER_DATA', { key: 'firstName', value: response.data.firstName })
            commit('SET_USER_DATA', { key: 'lastName', value: response.data.lastName })

            const localStorageUser = JSON.parse(localStorage.getItem('myCityCardsUser'))
            localStorageUser.email = response.data.email
            localStorageUser.gender = response.data.gender
            localStorageUser.salutation = response.data.salutation
            localStorageUser.firstName = response.data.firstName
            localStorageUser.lastName = response.data.lastName

            localStorage.setItem('myCityCardsUser', JSON.stringify(localStorageUser))

            commit('ADD_ALERT', { category: 'Info', message: 'Ihre persönlichen Daten wurden erfolgreich aktualisiert.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Speichern der persönlichen Daten ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getCompleteInterestPersonalData ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('interest-complete-personal-data')
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    getCompletePartnerPersonalData ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('partner-complete-personal-data')
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    getCompleteEmployerPersonalData ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('employer-complete-personal-data')
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    generateSepaPDF ({ commit }, sepaData) {
      return new Promise((resolve, reject) => {
        HTTP.post('generate-sepa-pdf', sepaData, {
          responseType: 'arraybuffer'
        })
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Das SEPA Lastschriftmandat konnte nicht generiert werden. Bitte überprüfen Sie alle Felder.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    generateReceipt ({ commit }, dealerReceiptData) {
      return new Promise((resolve, reject) => {
        HTTP.post('generate-receipt', dealerReceiptData, {
          responseType: 'arraybuffer'
        })
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Der Händlerbeleg konnte nicht generiert werden.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getInterestPartnerFAQs ({ commit }, regionData) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/' + regionData.regionID + '?_fields=acf.interest_partner_faqs,acf.contact_person,acf.email,acf.phone,acf.personenbezeichnung', {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getPartnerFAQs ({ commit }, regionData) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/' + regionData.regionID + '?_fields=acf.partner_faqs,acf.contact_person,acf.email,acf.phone,acf.personenbezeichnung', {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    checkEcTerminal ({ commit }, handlingAtPosData) {
      return new Promise((resolve, reject) => {
        HTTP.post('check-ec-terminal', handlingAtPosData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Ihr Anfrage zur Prüfung des EC-Terminals wurde erfolgreich verschickt. Nach der Prüfung setzen wir uns mit Ihnen in Verbindung.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Versenden des Formulares ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Versenden des Formulares ist ein Fehler aufgetreten.'))
          })
      })
    },
    getInterestEmployerFAQs ({ commit }, regionData) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/' + regionData.regionID + '?_fields=acf.interest_employer_faqs,acf.contact_person,acf.email,acf.phone,acf.personenbezeichnung', {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getEmployerFAQs ({ commit }, regionData) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/' + regionData.regionID + '?_fields=acf.employer_faqs,acf.contact_person,acf.email,acf.phone,acf.personenbezeichnung', {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getPartnerConditionsImage ({ commit }, regionData) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/' + regionData.regionID + '?_fields=acf.partner_conditions_image', {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getEmployerConditionsImage ({ commit }, regionData) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/' + regionData.regionID + '?_fields=acf.employer_conditions_image', {
          auth: {
            username: 'ansicht',
            password: 'trolleymaker5!'
          }
        })
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getAppointmentIframeUrl ({ commit }, regionData) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://regionen.trolleymaker.com/wp-json/wp/v2/regionen/' + regionData.regionID + '?_fields=acf.appointment_iframe_url,acf.appointment_iframe_url_interest,acf.appointment_iframe_url_mitarbeitercard')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
          })
      })
    },
    getBonusData ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('get-bonus')
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    setBonus ({ commit }, bonusData) {
      return new Promise((resolve, reject) => {
        HTTP.post('set-bonus', bonusData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Ihr Bonusauftrag wurde erfolgreich erteilt. Bitte beachten Sie, dass die Aktivierung und Änderungen/Anpassungen Ihres Bonus bis zu 3 Werktage (Mo-Fr) beanspruchen kann.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Versenden des Bonusauftrag ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Versenden des Bonusauftrag ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'))
          })
      })
    },
    getCorrectionBookingForm ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('get-correction-booking')
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    correctionBookingForm ({ commit }, bonusData) {
      return new Promise((resolve, reject) => {
        HTTP.post('correction-booking', bonusData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Ihr Korrekturbuchungsauftrag wurde erfolgreich erteilt. Sie erhalten eine Bestätigungsmail, nach erfolgter Korrektur.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Beim Versenden des Korrekturbuchungsauftrag ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Versenden des Korrekturbuchungsauftrag ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'))
          })
      })
    },
    partnerCheckBalance ({ commit }, inputCardID) {
      return new Promise((resolve, reject) => {
        HTTP.post('partner-check-balance', inputCardID)
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    getBookingData ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('get-booking')
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    getPartnerEmployerDocuments ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('partner-employer-documents')
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    downloadDocument ({ commit }, documentData) {
      return new Promise((resolve, reject) => {
        HTTP.post('partner-employer-documents', documentData, { responseType: 'blob' })
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    partnerGuthabenEinloesen ({ commit }, guthabenEinloesenData) {
      return new Promise((resolve, reject) => {
        HTTP.post('partner-guthaben-einloesen', guthabenEinloesenData)
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten.')
            error.errorMessage = errorMessage
            error.errorStatusCode = error.response.data.errorStatusCode
            // commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    partnerKundenbonusAufladen ({ commit }, kundenbonusAufladenData) {
      return new Promise((resolve, reject) => {
        HTTP.post('partner-kundenbonus-aufladen', kundenbonusAufladenData)
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten.')
            error.errorMessage = errorMessage
            error.errorStatusCode = error.response.data.errorStatusCode
            // commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    partnerGuthabenAufladen ({ commit }, guthabenAufladenData) {
      return new Promise((resolve, reject) => {
        HTTP.post('partner-guthaben-aufladen', guthabenAufladenData)
          .then(response => {
            if (!response) {
              reject(new Error())
            }
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten.')
            error.errorMessage = errorMessage
            error.errorStatusCode = error.response.data.errorStatusCode
            // commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(error)
          })
      })
    },
    checkEmailExists ({ commit }, emailData) {
      return new Promise((resolve, reject) => {
        HTTP.post('email-exists-check', emailData)
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten. Es konnte nicht geprüft werden, ob die E-Mail-Adresse bereits existiert. Bitte wenden Sie sich an den Support.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    getCommunitiesForRegion ({ commit }, regionData) {
      return new Promise((resolve, reject) => {
        HTTP.get('communities-for-region?regionName=' + regionData.regionName + '&regionSlug=' + regionData.regionSlug)
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten. Die Gemeindezugehörigkeiten konnte nicht abgerufen werden.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    getInterestRegistrationFormValues ({ commit }, gguid) {
      return new Promise((resolve, reject) => {
        let url = 'interest-registration-form-values'
        if (typeof gguid !== 'undefined' && typeof gguid.gguid !== 'undefined') {
          url += '?id=' + gguid.gguid
        }
        HTTP.get(url)
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten. Die Daten konnte nicht abgerufen werden.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(errorMessage)
          })
      })
    },
    getCustomerRegistrationFormValues ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('customer-registration-form-values')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten. Die Daten konnte nicht abgerufen werden.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(errorMessage)
          })
      })
    },
    getCategories ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('categories')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten. Die Kategorien konnte nicht abgerufen werden.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(errorMessage)
          })
      })
    },
    getEmployerCards ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('employer-cards')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(errorMessage)
          })
      })
    },
    checkIfMaintenance ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('maintenance-check')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Es konnte nicht geprüft werden, ob Wartungsarbeiten stattfinden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Es ist ein Fehler aufgetreten.'))
          })
      })
    },
    updateEmployerCard ({ commit }, employerCardData) {
      return new Promise((resolve, reject) => {
        HTTP.post('update-employer-card', employerCardData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Die Daten wurden erfolgreich aktualisiert.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Es ist ein Fehler aufgetreten.'))
          })
      })
    },
    updateMultipleEmployerCards ({ commit }, employerCardsData) {
      return new Promise((resolve, reject) => {
        HTTP.post('update-multi-employer-cards', employerCardsData)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Die Daten wurden erfolgreich aktualisiert.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Es ist ein Fehler aufgetreten.'))
          })
      })
    },
    getPushNotificationsData ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('push-notifications')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    savePushNotification ({ commit }, pushNotificationToSend) {
      return new Promise((resolve, reject) => {
        HTTP.post('push-notifications', pushNotificationToSend)
          .then(response => {
            commit('ADD_ALERT', { category: 'Info', message: 'Die Push Notification wurden erfolgreich gespeichert.' })
            resolve()
          }, error => {
            const errorMessage = parseError(error, 'Es ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Es ist ein Fehler aufgetreten.'))
          })
      })
    },
    getRegionDataThroughBackend ({ commit }, requestData) {
      return new Promise((resolve, reject) => {
        if (Array.isArray(requestData.fields)) {
          requestData.fields.join(',')
        }
        HTTP.get('region-data?fields=' + requestData.fields)
          .then(response => {
            resolve(response.data)
          }, error => {
            reject(error)
          })
      })
    },
    abfrage ({ commit }, x) {
      return new Promise((resolve, reject) => {
        HTTP.get('abfrage?x=' + x.x)
          .then(response => {
            resolve(response.data)
          }, error => {
            reject(error)
          })
      })
    },
    loadImprintText ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://rechtliches.trolleymaker.com/impressum.html')
          .then(response => {
            resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Fehler', message: 'Beim Abrufen des Impressums ist ein Fehler aufgetreten.' })
            reject(new Error('Beim Abrufen des Impressums ist ein Fehler aufgetreten.'))
          })
      })
    },
    loadConditionsOfParticipationText ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://rechtliches.trolleymaker.com/teilnahmebedingungen.html')
          .then(response => {
            resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Fehler', message: 'Beim Abrufen der Teilnahmebedingungen ist ein Fehler aufgetreten.' })
            reject(new Error('Beim Abrufen des Teilnahmebedingungen ist ein Fehler aufgetreten.'))
          })
      })
    },
    loadDeclarationOfConsentText ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://rechtliches.trolleymaker.com/einwilligungserklaerung.html')
          .then(response => {
            resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Fehler', message: 'Beim Abrufen der Einwilligungserklärung ist ein Fehler aufgetreten.' })
            reject(new Error('Beim Abrufen der Einwilligungserklärung ist ein Fehler aufgetreten.'))
          })
      })
    },
    loadPrivacyPolicyText ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://rechtliches.trolleymaker.com/datenschutzhinweise.html')
          .then(response => {
            resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Fehler', message: 'Beim Abrufen der Datenschutzhinweise ist ein Fehler aufgetreten.' })
            reject(new Error('Beim Abrufen der Datenschutzhinweise ist ein Fehler aufgetreten.'))
          })
      })
    },
    loadDataProtectionText ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('https://rechtliches.trolleymaker.com/datenschutzerklaerung-portale.html')
          .then(response => {
            resolve(response.data)
          }, () => {
            commit('ADD_ALERT', { category: 'Fehler', message: 'Beim Abrufen der Datenschutzerklärung ist ein Fehler aufgetreten.' })
            reject(new Error('Beim Abrufen der Datenschutzerklärung ist ein Fehler aufgetreten.'))
          })
      })
    },
    getPasswordResetList ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('reset-password-tokens')
          .then(response => {
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Die Daten konnten nicht abgerufen werden')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error(errorMessage))
          })
      })
    },
    uploadGenerateCheckBalanceURLs ({ commit }, cardsListFile) {
      return new Promise((resolve, reject) => {
        HTTP.post('generate-qrcode-links-csv', cardsListFile, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob'
        })
          .then(response => {
            var blob = new Blob([response.data], { type: 'text/csv' })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'generierte_guthabenabfrage-links'
            link.click()
            commit('ADD_ALERT', { category: 'Info', message: 'Die generierte Datei wurde erfolgreich heruntergeladen' })
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Beim Hochladen der Datei ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Hochladen ist ein Fehler aufgetreten.'))
          })
      })
    },
    uploadGeneratePotentialPartnersRegistrationURLs ({ commit }, partnersListFile) {
      return new Promise((resolve, reject) => {
        HTTP.post('generate-potential-partners-registration-links-csv', partnersListFile, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob'
        })
          .then(response => {
            var blob = new Blob([response.data], { type: 'text/csv' })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'generierte-registrierungs-links'
            link.click()
            commit('ADD_ALERT', { category: 'Info', message: 'Die generierte Datei wurde erfolgreich heruntergeladen' })
            resolve(response.data)
          }, error => {
            const errorMessage = parseError(error, 'Beim Hochladen der Datei ist ein Fehler aufgetreten.')
            commit('ADD_ALERT', { category: 'Fehler', message: errorMessage })
            reject(new Error('Beim Hochladen ist ein Fehler aufgetreten.'))
          })
      })
    }
  }
})
